/** @jsxImportSource @emotion/react */

import { Box, Button, Typography, useTheme } from '@mui/material'
import { ElementAccordion } from './ElementAccordion'
import {
  informationColumn,
  riskSummaryFilterBanner,
  riskSummaryFooter,
  riskSummaryFooterInner,
  scrollingRiskBody,
} from './RiskSummary.styles'
import { Fragment, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSideDrawerInfoLayerFocus } from '@redux/sideDrawer/sideDrawerSlice'
import ForumIcon from '@mui/icons-material/Forum'
import { EmptySideDrawer } from '../../EmptyTab'
import { GroupedLayers } from '..'
import { css } from '@emotion/react'
import { SideDrawerDivider } from '../../../components/SideDrawerDivider'
import { RootState } from '@redux/store'
import { useMap } from '@contexts/MapContext'
import { usePreferences } from '@contexts/PreferencesContext'
import { updateUserStateProperty } from '@redux/user/userSlice'
import { useLayers } from '@contexts/LayerContext'
import { useRiskFetcher } from '@contexts/RiskFetcherContext'

export const RiskSummary = ({ groupedLayers }: { groupedLayers: GroupedLayers }) => {
  const theme = useTheme()
  const { term_preference } = usePreferences()
  const { drawAreas } = useSelector((state: RootState) => state.map)
  const { regionMasks } = useMap()
  const { elementFilters } = useLayers()
  const dispatch = useDispatch()

  const { riskToAssets, isLoading } = useRiskFetcher()

  const hasElementFilters = useMemo(() => {
    return Object.values(elementFilters).some((filters) => Object.keys(filters).length > 0)
  }, [elementFilters])

  // Gather Layer info
  const hasAssets = useMemo(() => {
    return groupedLayers.assets.length > 0
  }, [groupedLayers])
  const hasHazards = useMemo(() => {
    return groupedLayers.hazards.length > 0
  }, [groupedLayers])
  const hadNoLayers = useRef(true)

  useEffect(() => {
    const checkHasLayers = Object.values(groupedLayers).some((layers) => layers.length > 0)
    // Open layer focus if layers were just added for the first time
    if (checkHasLayers && hadNoLayers.current) {
      hadNoLayers.current = false
      dispatch(setSideDrawerInfoLayerFocus({ layerFocus: 'risk', isOpen: true }))
    } else if (!checkHasLayers) {
      hadNoLayers.current = true
    }
  }, [dispatch, groupedLayers])

  const body = useMemo(() => {
    const layerAccordionCommonProps = (index: number) => ({
      groupedLayers,
      animationDelay: index / 10,
    })
    if (hasAssets && hasHazards) {
      return (
        <Box css={informationColumn({ theme })}>
          {groupedLayers.assets.map((layer, index) => (
            <Fragment key={index}>
              {index !== 0 && <SideDrawerDivider />}
              <ElementAccordion
                layer={layer}
                riskToAssets={riskToAssets}
                {...layerAccordionCommonProps(index)}
                isLoading={isLoading}
              />
            </Fragment>
          ))}
        </Box>
      )
    } else if (hasAssets) {
      return (
        <EmptySideDrawer
          text={
            term_preference.hazard
              ? 'No hazards are visible on the map. Add hazards to gain insights'
              : 'No risk sources are visible on the map. Add risk sources to gain insights'
          }
          type="hazard"
          buttonText={term_preference.hazard ? 'Add Hazards' : 'Add Risk Sources'}
        />
      )
    } else if (hasHazards) {
      return (
        <EmptySideDrawer
          text="No elements are visible on the map. Add elements to gain insights"
          type="element"
          buttonText="Add Elements"
        />
      )
    }
    return (
      <EmptySideDrawer
        text="No layers are visible on the map. Add layers to gain insights"
        type="element"
        buttonText="Add Layers"
      />
    )
  }, [hasAssets, hasHazards, groupedLayers, theme, riskToAssets, isLoading, term_preference.hazard])

  return (
    <Box
      css={css`
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: stretch;
      `}
    >
      <Box css={scrollingRiskBody({ theme })}>
        {hasAssets && hasHazards && (
          <>
            {!drawAreas.length && regionMasks && regionMasks.length > 0 && (
              <Typography css={riskSummaryFilterBanner({ theme })}>
                Filtering by selected regions
              </Typography>
            )}
            {drawAreas.length > 0 && (
              <Typography css={riskSummaryFilterBanner({ theme })}>
                Filtering by custom shape
              </Typography>
            )}
          </>
        )}
        {hasAssets && hasHazards && hasElementFilters && (
          <Typography css={riskSummaryFilterBanner({ theme })}>
            Filering by element attributes
          </Typography>
        )}
        {body}
        <SideDrawerDivider />
        {hasAssets && hasHazards && (
          <Box css={riskSummaryFooter}>
            <Box css={riskSummaryFooterInner}>
              <Button
                size="medium"
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<ForumIcon />}
                sx={{
                  marginBottom: theme.spacing(2),
                }}
                onClick={() => dispatch(updateUserStateProperty({ show_feedback_modal: true }))}
              >
                Submit Feedback
              </Button>
              <Box className="tutorial-risk-footer" />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

/** @jsxImportSource @emotion/react */

import { Box, IconButton, InputAdornment, List, TextField, useTheme } from '@mui/material'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

import { BASE_API_URL } from '@src/app-constants'
import {
  IconTypography,
  MoreInformationModal,
  snakeCaseToTitleCase,
} from '@uintel/ui-component-library'
import { HazardDetail, HazardDialogData, HazardScenario } from '../AddLayersDialog.types'
import {
  accordionContainer,
  accordionContainerSmallGap,
  bold700,
  hazardLayersFormContainer,
  hazardLayersFormContent,
} from './HazardLayersForm.styles'
import { Clear } from '@mui/icons-material'
import { HazardBox } from './HazardBox'
import { MapLayer, MapLayerType } from '@redux/map/mapSlice'
import HazardListItem from './HazardListItem/HazardListItem'
import { useSnackbars } from '@uintel/ui-component-library'
import { Accordion } from '@src/components/Atoms/Accordion'
import { UiclAccordion } from '@src/components/Atoms/Accordion/UiclAccordion'

export type HazardLayersFormProps = {
  hazardDialogData: HazardDialogData
  onSelectHazardLayer: (selectedHazard: MapLayer) => void
  onRemoveLayer: (layer: MapLayer, type: MapLayerType) => void
  selectedHazards: MapLayer[]
  selectedAssets: MapLayer[]
  isKnownRisks?: boolean
}

export const HazardLayersForm = ({
  hazardDialogData,
  selectedHazards,
  selectedAssets,
  onSelectHazardLayer,
  onRemoveLayer,
  isKnownRisks = false,
}: HazardLayersFormProps) => {
  const theme = useTheme()
  const [informationDetailsModal, setInformationDetailsModal] = useState({
    isOpen: false,
    helpFileName: '',
  })
  const [searchTerm, setSearchTerm] = useState('')
  const { displaySnackbar } = useSnackbars()

  // important clone! otherwise hazardDialogData will be mutated
  const hazardDialogDataCopy: HazardDialogData = JSON.parse(JSON.stringify(hazardDialogData))

  let groupFormattedHazards: { [key: string]: HazardDetail[] } =
    hazardDialogDataCopy.hazardDetails.reduce((acc, hazard) => {
      if (hazard.group) {
        if (acc[hazard.group]) {
          acc[hazard.group].push(hazard)
        } else {
          acc[hazard.group] = [hazard]
        }
      }
      return acc
    }, {} as { [key: string]: HazardDetail[] })
  if (Object.keys(groupFormattedHazards).length === 0) {
    groupFormattedHazards = {
      'Risk Sources': hazardDialogDataCopy.hazardDetails,
    }
  }

  const cascadingLayerIsPresent =
    selectedAssets && selectedAssets.some((asset) => asset.is_cascading)
  const cannotAddHazardLayer = cascadingLayerIsPresent && selectedHazards.length > 0

  const hasHazardIds = hazardDialogData.hazardDetails.every((hazardDetail) =>
    hazardDetail.scenarios.every((scenario) => scenario.hazard_id != undefined),
  )

  const groupIcons = Object.keys(groupFormattedHazards).reduce((acc, group) => {
    if (groupFormattedHazards[group][0].groupIcon) {
      acc[group] = groupFormattedHazards[group][0].groupIcon
    }
    return acc
  }, {} as { [key: string]: string })

  const handleAddLayerClick = (scenario: HazardScenario) => {
    if (
      selectedHazards.find((hazard) => {
        if (isKnownRisks || scenario.hazard_id === undefined)
          return hazard.assetTag === scenario.assetTag
        return scenario.hazard_id && hazard.hazard_id && scenario.hazard_id === hazard.hazard_id
      })
    ) {
      onRemoveLayer(scenario, 'hazard')
      return
    }
    if (cannotAddHazardLayer) {
      displaySnackbar({
        message:
          'While a cascading layer is active, you can only have one hazard active at a time. Please remove existing hazard layer before adding a new hazard layer.',
        type: 'error',
      })
      return
    }
    const formattedSelectedHazard: MapLayer = {
      ...scenario,
      display_name: scenario.display_name
        ? scenario.display_name
        : snakeCaseToTitleCase(scenario.assetTag),
      legend: scenario.legend,
      visible: true,
      interactivityDisabled: false,
      icon: scenario.icon ?? 'Warning',
      layerType: 'hazard',
    }
    onSelectHazardLayer(formattedSelectedHazard)
  }

  // sort hazardLayer.form by hazardLayer.form[n].priority
  const sortHazardLayerForm = (hazardLayer: HazardDetail) => {
    const sortedHazardLayer = {
      ...hazardLayer,
    }
    if (hazardLayer.form) {
      sortedHazardLayer.form = [...hazardLayer.form].toSorted((a, b) => {
        return a.priority - b.priority
      })
    }
    return sortedHazardLayer
  }

  // get all hazard scenarios that match display_name using searchTerm
  const searchedHazardScenarios = useMemo(() => {
    const flatScenarios = hazardDialogData.hazardDetails.flatMap((hazard) => hazard.scenarios)
    const foundScenarios = flatScenarios.filter((scenario) => {
      return scenario.display_name.toLowerCase().includes(searchTerm.toLowerCase())
    })

    // Only list one scenario per unique hazard id in results
    if (hasHazardIds) {
      const singleScenarioPerHazardId: HazardScenario[] = []
      const seenHazardIds: { [key: string]: boolean } = {}

      foundScenarios.forEach((foundScenario) => {
        if (foundScenario.hazard_id && !seenHazardIds[foundScenario.hazard_id]) {
          singleScenarioPerHazardId.push(foundScenario)
          seenHazardIds[foundScenario.hazard_id] = true
        }
      })

      return singleScenarioPerHazardId
    }

    return foundScenarios
  }, [hasHazardIds, hazardDialogData.hazardDetails, searchTerm])

  const AccordionToUse = isKnownRisks ? Accordion : (UiclAccordion as typeof Accordion)

  return (
    <>
      <Box css={hazardLayersFormContainer({ theme })}>
        {/* Search box */}
        <TextField
          css={{ margin: `0 ${theme.spacing(1)}` }}
          label="Search"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value)
          }}
          InputProps={{
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  edge="end"
                  onClick={() => {
                    setSearchTerm('')
                  }}
                >
                  <Clear />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Box css={hazardLayersFormContent()}>
          {searchTerm.length === 0 ? (
            <Box css={accordionContainer({ theme })}>
              {Object.keys(groupFormattedHazards).length === 1 &&
              groupFormattedHazards['Risk Sources'] ? (
                <>
                  {groupFormattedHazards['Risk Sources'] &&
                    groupFormattedHazards['Risk Sources'].map((hazardLayer) => (
                      <AccordionToUse
                        key={hazardLayer.title}
                        lazyContent
                        level="h3"
                        variant="outline"
                        header={
                          <IconTypography
                            icon={hazardLayer.groupIcon ? hazardLayer.groupIcon : ''}
                            css={bold700}
                          >
                            {hazardLayer.title}
                          </IconTypography>
                        }
                        body={
                          <HazardBox
                            hazardDetail={sortHazardLayerForm(hazardLayer)}
                            selectedHazards={selectedHazards}
                            addingDisallowed={cannotAddHazardLayer}
                            allScenarios={
                              hazardDialogData.hazardDetails.find((hDetail) => {
                                return hDetail.title === hazardLayer.title
                              })?.scenarios ?? hazardLayer.scenarios
                            }
                            setInformationDetailsModal={setInformationDetailsModal}
                            addHazardScenario={handleAddLayerClick}
                            isKnownRisks={isKnownRisks}
                          />
                        }
                      />
                    ))}
                </>
              ) : (
                <>
                  {Object.keys(groupFormattedHazards).map((hazardGroupKey) => (
                    <AccordionToUse
                      key={hazardGroupKey}
                      defaultExpanded={true}
                      level="h3"
                      variant="filled"
                      header={
                        <IconTypography
                          icon={groupIcons[hazardGroupKey] ? groupIcons[hazardGroupKey] : ''}
                        >
                          {hazardGroupKey}
                        </IconTypography>
                      }
                      body={
                        <Box css={accordionContainerSmallGap({ theme })}>
                          {groupFormattedHazards[hazardGroupKey]?.map((hazardLayer) => (
                            <AccordionToUse
                              key={hazardLayer.title}
                              lazyContent
                              level="h3"
                              variant="outline"
                              header={
                                <IconTypography
                                  icon={hazardLayer.group ? hazardLayer.icon : ''}
                                  css={bold700}
                                >
                                  {hazardLayer.title}
                                </IconTypography>
                              }
                              body={
                                <HazardBox
                                  hazardDetail={sortHazardLayerForm(hazardLayer)}
                                  selectedHazards={selectedHazards}
                                  addingDisallowed={cannotAddHazardLayer}
                                  allScenarios={
                                    hazardDialogData.hazardDetails.find((hDetail) => {
                                      return hDetail.title === hazardLayer.title
                                    })?.scenarios ?? hazardLayer.scenarios
                                  }
                                  setInformationDetailsModal={setInformationDetailsModal}
                                  addHazardScenario={handleAddLayerClick}
                                  isKnownRisks={isKnownRisks}
                                />
                              }
                            />
                          ))}
                        </Box>
                      }
                    ></AccordionToUse>
                  ))}
                </>
              )}
            </Box>
          ) : (
            <Box>
              <List disablePadding>
                {searchedHazardScenarios.map((scenario: HazardScenario) => {
                  const selected = !!selectedHazards.find((sHazard) => {
                    if (isKnownRisks || scenario.hazard_id === undefined)
                      return sHazard.assetTag === scenario.assetTag
                    return sHazard.hazard_id === scenario.hazard_id
                  })
                  return (
                    <HazardListItem
                      selected={selected}
                      disallowed={cannotAddHazardLayer}
                      key={scenario.assetTag}
                      scenario={scenario}
                      addHazardScenario={handleAddLayerClick}
                    />
                  )
                })}
              </List>
            </Box>
          )}
        </Box>
      </Box>
      {informationDetailsModal.isOpen &&
        createPortal(
          <MoreInformationModal
            baseURL={BASE_API_URL}
            helpFileName={informationDetailsModal.helpFileName}
            isOpen={informationDetailsModal.isOpen}
            handleClose={() =>
              setInformationDetailsModal({
                isOpen: false,
                helpFileName: '',
              })
            }
          />,
          document.body,
        )}
    </>
  )
}

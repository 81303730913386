import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const chartContainer = () => css`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  flex-grow: 1;
  margin-top: 8px;
  position: relative;
  min-width: 300px;
  overflow: hidden;
  min-height: 180px;
`

export const chartSizeProtector = () => css`
  width: 100%;
  height: 100%;
  position: absolute;
`

export const chartSection = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  gap: ${theme.spacing(1)};
  flex-grow: 1;
`

export const radioGroupContent = ({ theme }: { theme: Theme }) => css`
  margin-top: ${theme.spacing(1)};
  margin-left: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(1)};
  gap: 0 12px !important;
  @media (min-width: 1px) {
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(2)};
    span {
      font-size: 12px;
    }
  }
`

export const radioGroup = ({ theme }: { theme: Theme }) => css`
  padding-right: ${theme.spacing(2)};
  padding-left: ${theme.spacing(12)};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .MuiCheckbox-root {
    padding: 4px 8px;
  }
`

export const toggleGroup = ({ theme }: { theme: Theme }) => css`
  button {
    border-color: ${theme.palette.primary.main}40;
    padding: 4px 16px;

    transition: background-color 0.15s, border-color 0.15s;

    &:hover,
    &:active {
      background-color: ${theme.palette.primary.main}20;

      p {
        color: ${theme.palette.primary.main};
      }
    }

    p {
      line-height: 1.4;
      letter-spacing: 0px;
      color: ${theme.palette.text.primary};
    }

    &.Mui-selected {
      background-color: ${theme.palette.primary.main};
      border-color: ${theme.palette.primary.main};
      box-shadow: 0 0 3px ${theme.palette.primary.main}88;

      p {
        color: #fff;
      }

      &:hover {
        background-color: ${theme.palette.primary.main};
      }
    }
  }
`

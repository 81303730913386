import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

interface LandusePageContainerProps {
  theme: Theme
}

export const LandusePageContainer = ({ theme }: LandusePageContainerProps) => css`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;

  width: 100%;
  height: 100svh;
  min-height: 100%;

  overflow: auto;

  margin: 0 auto;
  background-color: ${theme.palette.white.main};
`

export const LandusePageLeft = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;

  background-color: ${theme.palette.primary.main};

  width: 50%;
  min-height: 100%;
  height: 100%;
  padding: 80px;

  @media (max-width: 1300px) {
    width: 45%;
  }
  @media (max-width: 860px) {
    width: 100vw;
    padding: 40px;
  }
  @media (max-width: 460px) {
    padding: 20px;
  }
`

export const LandusePageLeftInnerContainer = () => css`
  @media (max-width: 860px) {
    width: 80vw;
  }
`

export const ShowOnMobile = () => css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;

  @media (min-width: 860px) {
    display: none;
  }
`

export const MobileBodyText = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.white.main};
  margin: 8px 0;

  a {
    color: ${theme.palette.white.main};
    text-decoration: underline;
  }
`

export const MobileHeaderText = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.white.main};
  margin: 8px 0;
  font-weight: 700;
`

export const LandusePageRight = ({ theme }: { theme: Theme }) => css`
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${theme.palette.secondary.main};

  width: 50%;
  min-height: 100%;
  max-height: 100%;
  padding: 40px;

  img {
    width: 100%;
    max-width: 1000px;
  }

  @media (max-width: 1300px) {
    width: 55%;
  }

  @media (max-width: 860px) {
    display: none;
  }
`

export const mainText = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.white.main};
  font-weight: 900;
  font-size: 2.8rem;
  line-height: 3.8rem;
  max-width: 650px;

  @media (max-width: 1410px) {
    font-size: 2rem;
    line-height: 3rem;
  }

  @media (max-width: 460px) {
    font-size: 1.4rem;
    line-height: 2rem;
  }
`

export const secondaryTextText = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.white.main};
  font-weight: 300;
  margin-top: 80px;
  display: flex;

  @media (max-width: 1410px) {
    font-size: 1rem;
  }

  @media (max-width: 460px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    color: ${theme.palette.white.main};
    text-decoration: underline;
    text-underline-offset: ${theme.spacing(1)};
    margin-left: ${theme.spacing(2)};
  }
`

export const secondaryTextPrimary = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.primary.main};
  font-weight: 700;
  margin: 24px 0;
  display: flex;

  @media (max-width: 1410px) {
    font-size: 3rem;
  }

  @media (max-width: 460px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    color: ${theme.palette.black.main};
    text-decoration: underline;
    text-underline-offset: ${theme.spacing(1)};
    margin-left: ${theme.spacing(2)};
  }
`

export const content = css`
  a {
    text-decoration: none;
  }
`

export const demoButton = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;

  margin: ${theme.spacing(6)} 0px;

  @media (max-width: 460px) {
    justify-content: center;
  }
`

export const footer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${theme.spacing(3)};
  width: 100%;

  @media (max-width: 460px) {
    justify-content: center;
  }
`

export const socialLinks = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: start;
  justify-content: center;

  gap: ${theme.spacing(3)};
  color: ${theme.palette.white.main};

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.palette.white.main};
    height: 32px;
    width: 32px;

    @media (max-width: 1410px) {
      height: 28px;
      width: 28px;
    }

    svg {
      width: 100%;
      height: 100%;
      transition: color 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        color: ${theme.palette.white.dark};
      }
    }
  }
`

export const footerText = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.white.dark};
  font-weight: 500;

  @media (max-width: 1410px) {
    font-size: 1rem;
  }
`

export const logo = css`
  height: 120px;
  margin-top: -22px;
  margin-bottom: -18px;

  @media (max-width: 1410px) {
    height: 80px;
  }
`

export const marketingButton = ({ theme }: { theme: Theme }) => css`
  margin-top: ${theme.spacing(6)};
  margin-bottom: ${theme.spacing(6)};
  color: ${theme.palette.white.main};
  border-color: ${theme.palette.white.main};

  @media (max-width: 1410px) {
    font-size: 1rem;
  }
`

export const unorderedListBox = ({ theme }: { theme: Theme }) => css`
  padding-left: ${theme.spacing(4)};
`

import { MapLayer, MapLayerType } from '@redux/map/mapSlice'
import { BASE_API_URL, PUBLIC_ASSETS_BASE_URL } from '@src/app-constants'
import { AssetDialogData, HazardDetail } from '@src/components/Molecules/MapView/AddLayersDialog'
import { BaseMapLabel } from '@src/components/Pages/Map/BaseMapButton'
import axios from '@src/utils/customAxios'
import { snakeCaseToTitleCase } from '@uintel/ui-component-library'

export type StoryLocation = {
  latitude: number
  longitude: number
  zoom: number
  pitch?: number
  bearing?: number
}

export type StoryMapState = {
  elements?: string[]
  scenarios?: string[]
  contexts?: string[]
  basemap?: BaseMapLabel
  regions?: string[]
  location?: StoryLocation
}

export type StepSection = {
  header?: string
  content?: string
  wideImage?: string
  sideImage?: string
  videoUrl?: string
  buttons?: {
    url: string
    title: string
  }[]
}

export type Step = {
  title: string
  sections: StepSection[]
} & StoryMapState

export type Chapter = {
  title?: string
  description?: string
  steps: Step[]
} & StoryMapState

export type Story = {
  title: string
  description: string
  coverImageUrl?: string
  chapters: Chapter[]
} & StoryMapState

// Contains flattened additional info from parent chapter & story
export type SequencedStep = {
  sections: StepSection[]
  stepTitle: string
  chapterTitle?: string
  sequencedIndex: number
  elementsText?: string
  scenariosText?: string
  contextsText?: string
} & StoryMapState

// FETCH LAYERS
const fetchLayers = async () => {
  const response = await axios.get(`${BASE_API_URL}/api/layer`)
  return response.data
}

// Fetch layers data
export const getIndexedLayers = async () => {
  const layerData = await fetchLayers()

  // Collect scenarios by their assetTags
  const scenariosData: { [key: string]: MapLayer } = {}
  layerData.hazardDialogData.hazardDetails.forEach((hazard: HazardDetail) => {
    hazard.scenarios.forEach((scenario) => {
      scenariosData[scenario.assetTag] = scenario
    })
  })

  // Collect elements by their ids
  const elementsData: { [key: string]: MapLayer } = {}
  Object.values(layerData.assetDialogData as AssetDialogData).forEach((domain) => {
    domain.groups.forEach((group) => {
      group.assets.forEach((element) => {
        elementsData[element.type] = element
      })
    })
  })

  // Collect contexts by their ids
  const contextsData: { [key: string]: MapLayer } = {}
  Object.values(layerData.informationDialogData as MapLayer[]).forEach((layer) => {
    contextsData[layer.id] = layer
  })
  return { elementsData, scenariosData, contextsData }
}

const getStoryOverviewSequencedStep = (story: Story): SequencedStep => {
  return {
    stepTitle: story.title,
    sections: [
      {
        content: story.description,
      },
    ],
    location: story.location,
    sequencedIndex: 0,
    basemap: story.basemap,
  }
}

const getInheritedProperty = function <T>(
  property: keyof StoryMapState,
  stepIndex: number,
  chapterIndex: number,
  story: Story,
): T | undefined {
  if (story.chapters[chapterIndex].steps[stepIndex][property]) {
    return story.chapters[chapterIndex].steps[stepIndex][property] as T
  }
  if (story.chapters[chapterIndex][property]) {
    return story.chapters[chapterIndex][property] as T
  }
  if (story[property]) {
    return story[property] as T
  }
  return undefined
}

export const generateSequencedSteps = (
  story: Story,
  elementsData: {
    [key: string]: MapLayer
  },
  scenariosData: {
    [key: string]: MapLayer
  },
  contextsData: {
    [key: string]: MapLayer
  },
): SequencedStep[] => {
  const steps: SequencedStep[] = [getStoryOverviewSequencedStep(story)]
  for (let i = 0; i < story.chapters.length; i++) {
    for (let j = 0; j < story.chapters[i].steps.length; j++) {
      const newStep: SequencedStep = {
        sequencedIndex: steps.length,
        chapterTitle: j == 0 ? story.chapters[i].title : undefined,
        stepTitle: story.chapters[i].steps[j].title,
        sections: story.chapters[i].steps[j].sections,
        location: getInheritedProperty('location', j, i, story),
        elements: getInheritedProperty<string[]>('elements', j, i, story),
        scenarios: getInheritedProperty<string[]>('scenarios', j, i, story),
        contexts: getInheritedProperty<string[]>('contexts', j, i, story),
        basemap: getInheritedProperty<BaseMapLabel>('basemap', j, i, story) || 'Street',
        regions: getInheritedProperty<string[]>('regions', j, i, story),
      }
      if (newStep.elements && newStep.elements.length > 0) {
        newStep.elementsText = newStep.elements
          .flatMap((elementId) => {
            if (elementsData[elementId]) return [elementsData[elementId].display_name]
            return []
          })
          .join(', ')
      }

      if (newStep.scenarios?.length) {
        newStep.scenariosText = newStep.scenarios
          .flatMap((scenarioId) => {
            if (scenariosData[scenarioId]) return [scenariosData[scenarioId].display_name]
            return []
          })
          .join(', ')
      }

      if (newStep.contexts?.length) {
        newStep.contextsText = newStep.contexts
          .flatMap((contextId) => {
            if (contextsData[contextId]) return [contextsData[contextId].display_name]
            return []
          })
          .join(', ')
      }

      steps.push(newStep)
    }
  }
  return steps
}
export const getCurrentElements = (elementsData: {
  [key: string]: MapLayer
}): { [key: string]: MapLayer } => {
  return (
    Object.entries(elementsData)?.reduce((acc, [layerId, mapLayer]) => {
      acc[layerId] = {
        ...mapLayer,
        visible: true,
        interactivityDisabled: false,
        layerType: 'asset' as MapLayerType, // or hazard or information
        id: mapLayer.tilesets?.[0]?.id,
      }
      return acc
    }, {} as { [key: string]: MapLayer }) ?? {}
  )
}

export const getCurrentHazards = (scenariosData: {
  [key: string]: MapLayer
}): { [key: string]: MapLayer } => {
  return (
    Object.entries(scenariosData)?.reduce((acc, [layerId, mapLayer]) => {
      acc[layerId] = {
        ...mapLayer,
        display_name: mapLayer.display_name
          ? mapLayer.display_name
          : snakeCaseToTitleCase(mapLayer.assetTag),
        legend: mapLayer.legend,
        visible: true,
        interactivityDisabled: false,
        icon: mapLayer.icon ?? 'Warning',
        layerType: 'hazard',
        id: mapLayer.tilesets?.[0]?.id,
      }
      return acc
    }, {} as { [key: string]: MapLayer }) ?? []
  )
}
export const getCurrentContexts = (contextsData: {
  [key: string]: MapLayer
}): { [key: string]: MapLayer } => {
  return (
    Object.entries(contextsData)?.reduce((acc, [layerId, mapLayer]) => {
      acc[layerId] = {
        ...mapLayer,
        visible: true,
        interactivityDisabled: false,
        layerType: 'information',
        id: mapLayer.tilesets?.[0]?.id,
      }
      return acc
    }, {} as { [key: string]: MapLayer }) ?? {}
  )
}

export const addCloudfrontUrl = (url: string | undefined) => {
  if (!url) return undefined
  if (!url.includes(PUBLIC_ASSETS_BASE_URL)) {
    const newUrl = new URL(('public/' + url).replaceAll('//', '/'), PUBLIC_ASSETS_BASE_URL)
    return newUrl.toString()
  }
  return url
}

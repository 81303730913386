/** @jsxImportSource @emotion/react */

import {
  RiskData,
  useRiskFetcher,
  VulnBucket,
} from '@contexts/RiskFetcherContext/RiskFetcherContext'
import { Box, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material'
import { DrawArea, MapLayer } from '@redux/map/mapSlice'
import {
  chartContainer,
  chartHeader,
  chartSection,
  chartSizeProtector,
  chartsContainer,
  consqMethImage,
  disclaimerText,
  reportAssetRiskStackedSection,
  riskSummary,
} from './RiskContent.styles'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { capitalizeFirstLetter } from '@src/utils/strings.utils'
import { css } from '@emotion/react'
import { EmptySection } from '../../../../components/EmptySection'
import { useMap } from '@contexts/MapContext'
import { useConsequenceMethodologyFetcher } from '../../../../data_fetchers/consequenceMethodologyFetcher'
import { Accordion } from '../../../../../../Atoms/Accordion'
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined'
import { SourceButton } from '../../../../components/SourceButton'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'
import { Barchart, ChartDataItem } from '@src/components/Molecules/Charts/Barchart'
import { memo, useCallback, useMemo } from 'react'
import {
  getConsequencePalette,
  round,
  roundForDisplay,
} from '@src/components/Molecules/Charts/utils/utils'
import { Gauge } from '@src/components/Molecules/Charts/Gauge'
import ProjectedRisk from './ProjectedRisk/ProjectedRisk'
import { Histogram, HistogramDataItem } from '@src/components/Molecules/Charts/Histogram'
import { ChartTooltipItem } from '@src/components/Molecules/Charts/ChartTooltip'
import { useFeatureFlags } from '@contexts/FeatureFlagsContext'
import { RegionOption } from '@src/components/Molecules/RegionFilter'
import { FancySuspense } from '@src/components/Atoms/FancySuspense'
import { usePreferences } from '@contexts/PreferencesContext'

interface RiskContentProps {
  assetLayer: MapLayer
  hazardLayer: MapLayer
  riskToAssets: { [key: string]: RiskData[] }
  isLoading?: { [key: string]: boolean }
}
interface RiskContentInnerProps {
  assetLayer: MapLayer
  hazardLayer: MapLayer
  reportRiskData: RiskData | undefined
  regionMasks: RegionOption[] | null
  drawAreas: DrawArea[]
  isLoading: { [key: string]: boolean }
}

const vulnerabilityConverter = (
  vulnerability: string,
  palette: {
    [key: string]: string
  },
  isMinorMajor: boolean,
):
  | {
      label: string
      color: string
      order?: number
    }
  | undefined => {
  const trimmedVulnerability = vulnerability.replace('_vulnerability', '')
  return {
    potential: {
      label: 'Potential',
      color: palette['potential'],
      order: 2,
    },
    exposed: {
      label: 'Exposed',
      color: palette['exposed'],
      order: 1,
    },
    low: {
      label: isMinorMajor ? 'Minor' : 'Low',
      color: palette['low'],
      order: 3,
    },
    medium: {
      label: 'Moderate',
      color: palette['moderate'],
      order: 4,
    },
    high: {
      label: isMinorMajor ? 'Major' : 'High',
      color: palette['high'],
      order: 5,
    },
    unspecified: {
      label: 'Unspecified',
      color: palette['exposed'],
      order: 0,
    },
    insignificant: {
      label: 'Insignificant',
      color: palette['insignificant'],
      order: 0,
    },
  }[trimmedVulnerability]
}

export const buildHistogramData = (
  vulnBuckets: ({ exposure_bucket?: string | number; n_days?: number } & VulnBucket)[],
  palette: {
    [key: string]: string
  },
  isMinorMajor: boolean,
) => {
  const histogramData = vulnBuckets
    .map((bucket) => {
      const vulnDetails = Object.entries(bucket)
        .map(([key, value]) => ({
          ...vulnerabilityConverter(key, palette, isMinorMajor),
          value,
        }))
        .toSorted((a, b) => (b.order ?? 0) - (a.order ?? 0))
      const values: {
        [key: string]: number
      } = {}
      const colors: {
        [key: string]: string
      } = {}
      vulnDetails.forEach((curr) => {
        if (curr.label && curr.color) {
          values[curr.label] = curr.value as number
          colors[curr.label] = curr.color
        }
      })
      return {
        label: +(bucket.exposure_bucket ?? bucket.n_days ?? 0),
        value: values,
        color: colors,
      }
    })
    .sort((a, b) => +a.label - +b.label)
    .filter((a) => !isNaN(a.label))

  return histogramData
}

export const RiskContent = ({
  assetLayer,
  hazardLayer,
  riskToAssets,
  isLoading = {},
}: RiskContentProps) => {
  const theme = useTheme()
  const { term_preference } = usePreferences()
  const { consequenceMethodologyObject: consequenceMethodology, isLoading: consequenceIsLoading } =
    useConsequenceMethodologyFetcher(assetLayer, hazardLayer)
  const { selectedIdentifyingInformation } = useRiskFetcher()
  const thisIdentifyingInformation = useMemo(
    () => selectedIdentifyingInformation[assetLayer.type],
    [selectedIdentifyingInformation, assetLayer.type],
  )

  const { regionMasks } = useMap()
  const { drawAreas } = useSelector((state: RootState) => state.map)

  let riskDataContent = null

  const assetExposureStats = useMemo(() => {
    if (
      riskToAssets[
        thisIdentifyingInformation ? thisIdentifyingInformation.property ?? 'default' : 'default'
      ] === undefined
    ) {
      return undefined
    }
    return riskToAssets[
      thisIdentifyingInformation ? thisIdentifyingInformation.property ?? 'default' : 'default'
    ].find(
      (riskData) =>
        riskData.asset_type === assetLayer.type &&
        riskData.hazard_scenario === hazardLayer.assetTag,
    )
  }, [riskToAssets, thisIdentifyingInformation, assetLayer.type, hazardLayer.assetTag])

  if (assetExposureStats || isLoading) {
    riskDataContent = (
      <RiskContentInner
        assetLayer={assetLayer}
        hazardLayer={hazardLayer}
        reportRiskData={assetExposureStats}
        regionMasks={regionMasks}
        drawAreas={drawAreas}
        isLoading={isLoading}
      />
    )
  } else if ((regionMasks && regionMasks.length > 0) || drawAreas.length > 0) {
    riskDataContent = (
      <EmptySection
        isLoading={isLoading}
        title={
          isLoading
            ? 'Loading...'
            : 'There is no known exposure for this element from this hazard scenario in the selected areas.'
        }
      />
    )
  } else if (!regionMasks && drawAreas.length === 0) {
    riskDataContent = (
      <EmptySection
        isLoading={isLoading}
        title={
          isLoading
            ? 'Loading...'
            : 'There is no known exposure for this element from this hazard scenario.'
        }
      />
    )
  }

  return (
    <>
      {riskDataContent}
      {consequenceMethodology ? (
        <Accordion
          title={term_preference.risk ? 'Risk Methodology' : 'Consequence Methodology'}
          defaultExpanded={false}
          icon={<SourceOutlinedIcon />}
          variant="outline"
          level="h3"
          body={
            <>
              {consequenceMethodology.content && (
                <UiMarkdown>{consequenceMethodology.content}</UiMarkdown>
              )}
              {consequenceMethodology.image_url && (
                <img
                  alt={term_preference.risk ? 'Risk Methodology' : 'Consequence Methodology'}
                  src={consequenceMethodology.image_url}
                  css={consqMethImage({
                    theme,
                  })}
                />
              )}
              {consequenceMethodology.source && (
                <SourceButton source={consequenceMethodology.source}>Source</SourceButton>
              )}
            </>
          }
        />
      ) : (
        <EmptySection
          isLoading={consequenceIsLoading}
          customCSS={css`
            width: 100%;
          `}
          title={
            consequenceIsLoading
              ? 'Loading...'
              : `${term_preference.risk ? 'Risk' : 'Consequence'} Methodology unavailable`
          }
        />
      )}
    </>
  )
}

const RiskContentInner = memo(function RiskContentInner({
  assetLayer,
  hazardLayer,
  reportRiskData,
  isLoading,
}: RiskContentInnerProps) {
  const theme = useTheme()
  const { term_preference } = usePreferences()
  const { features } = useFeatureFlags()
  const replacementCostsEnabled = features.find(
    (feature) => feature.feature == 'replacementCosts' && feature.enabled,
  )
  const { cascadingAssetNDays } = useMap()
  const useCascading = cascadingAssetNDays !== null && assetLayer.is_cascading

  // Check if we're using the new consequences legend
  const isMinorMajor = useSelector((state: RootState) =>
    state.map.legendsData.vulnerability.sections.some(
      (section) =>
        section.items &&
        section.items.filter((item) => item.label === 'Minor' || item.label === 'Major').length > 0,
    ),
  )

  const vulnerabilityPalette = useSelector(
    (state: RootState) => state.map.legendsData['vulnerability'],
  )
  const palette = getConsequencePalette(vulnerabilityPalette) || {}
  let vulnerabilityData: ChartDataItem[] = []
  let replacementCostsData: ChartDataItem[] = []

  let exposurePercentage = 0
  let exposureTotal = 0
  let assetTotal = 0

  let histogramData: HistogramDataItem[] = []

  const {
    availableIdentifyingInformation,
    setIdentifyingInformationForAsset,
    selectedIdentifyingInformation,
  } = useRiskFetcher()

  const assetIdentifyingInformation = useMemo(
    () => availableIdentifyingInformation[assetLayer.type],
    [assetLayer.type, availableIdentifyingInformation],
  )

  if (reportRiskData) {
    const vulnSource: Partial<VulnBucket> = useCascading
      ? reportRiskData.data.n_days_buckets.find(
          (bucket) => bucket.n_days === cascadingAssetNDays[assetLayer.type],
        ) ?? {}
      : reportRiskData.data.totals
    vulnerabilityData = [
      ...Object.entries(vulnSource).flatMap(([key, value]) => {
        const vulnDetails = vulnerabilityConverter(key, palette, isMinorMajor)
        if (vulnDetails)
          return [
            {
              ...vulnDetails,
              value: value,
            },
          ]
        return []
      }),
    ]

    histogramData = buildHistogramData(
      useCascading ? reportRiskData.data.n_days_buckets : reportRiskData.data.exposure_buckets,
      palette,
      isMinorMajor,
    )

    if (reportRiskData?.attribute_stats?.replacement_costs) {
      replacementCostsData = [
        ...Object.entries(reportRiskData.attribute_stats.replacement_costs).flatMap(
          ([key, value]) => {
            const vulnDetails = vulnerabilityConverter(key, palette, isMinorMajor)
            if (vulnDetails)
              return [
                {
                  ...vulnDetails,
                  min: value.min,
                  value: value.value,
                  max: value.max,
                },
              ]
            return []
          },
        ),
      ]
    }

    exposurePercentage = ((vulnSource.total ?? 0) / reportRiskData.data.total_metric) * 100
    exposureTotal = Math.round((vulnSource.total ?? 0) * 10) / 10
    assetTotal = Math.round(reportRiskData.data.total_metric * 10) / 10
  }

  // If any of 'Low/Minor', 'Moderate', 'High/Major' > 0 then show them all as a group
  let lowModHighIsSet = vulnerabilityData.some(
    (item) => ['Low', 'Minor', 'Moderate', 'High', 'Major'].includes(item.label) && item.value > 0,
  )

  vulnerabilityData = vulnerabilityData.filter(
    (item) =>
      item.value ||
      (['Low', 'Minor', 'Moderate', 'High', 'Major'].includes(item.label) && lowModHighIsSet),
  )

  // If any of 'Low/Minor', 'Moderate', 'High/Major' > 0 then show them all as a group
  lowModHighIsSet = replacementCostsData.some(
    (item) => ['Low', 'Minor', 'Moderate', 'High', 'Major'].includes(item.label) && item.value > 0,
  )

  replacementCostsData = replacementCostsData.filter(
    (item) =>
      item.value ||
      (['Low', 'Minor', 'Moderate', 'High', 'Major'].includes(item.label) && lowModHighIsSet),
  )

  // Some models dont' have fragility curves so we can't show a damage state, all we can say for sure is that
  // the element is exposed. If the only stat available is 'Exposed' then show a message stating this.
  const isVulnerabilityOnlyExposed =
    vulnerabilityData.length == 1 && vulnerabilityData[0].label == 'Exposed'

  const isReplacementCostMode =
    selectedIdentifyingInformation[assetLayer.type]?.name === 'Replacement Costs'
  const xAxisTitle = `${
    assetLayer.metric_type === 'count'
      ? 'Number'
      : isReplacementCostMode
      ? 'Dollars worth'
      : capitalizeFirstLetter(assetLayer.unit?.trimStart() ?? '')
  } of ${
    useCascading
      ? 'parcels'
      : isReplacementCostMode
      ? assetLayer.display_name.toLocaleLowerCase()
      : assetLayer.display_name
  } ${isReplacementCostMode ? 'threatened' : ''}`

  const withArticle = (value: string) => {
    return /^[aeiouAEIOU]/i.test(value.trim()) ? `an ${value}` : `a ${value}`
  }

  const consequencesPossible = useCallback(
    (
      consequenceLabel: string,
      value: number,
      suffix: string | undefined,
      prefix = '',
      type?: string,
    ) => {
      const exposure = {
        Minor: 'Minor consequences',
        Low: 'Low consequences',
        Moderate: 'Moderate consequences',
        Major: 'Major consequences',
        High: 'High consequences',
        Potential: 'Potential consequences',
        Unspecified: 'Unspecified consequences',
        Insignificant: 'Insignificant consequences',
      }[consequenceLabel]
      let roundedValue = round(value, value > 1000 ? 0 : 2).toLocaleString()
      if (roundedValue === '0' && value !== 0) roundedValue = '<0.01'
      if (useCascading)
        return `${exposure} possible for  upstream connections of ${roundedValue} parcels`
      if (type === 'Replacement Costs')
        return `${prefix}${roundedValue} worth of damage from possible ${exposure?.toLocaleLowerCase()}`
      if (consequenceLabel === 'Exposed') return `${prefix}${roundedValue} ${suffix} are exposed`
      return `${exposure} possible for ${prefix}${roundedValue} ${suffix}`
    },
    [useCascading],
  )
  const damageStateTooltipFormatter = useCallback(
    (data: ChartDataItem) => {
      let unit = assetLayer.unit
      let prefix = undefined
      let type = undefined
      if (selectedIdentifyingInformation[assetLayer.type]) {
        unit = undefined
        if (selectedIdentifyingInformation[assetLayer.type]?.name === 'Replacement Costs') {
          unit = undefined
          type = 'Replacement Costs'
        }
        prefix = selectedIdentifyingInformation[assetLayer.type].prefix
      }
      return consequencesPossible(data.label, data.value, unit, prefix, type)
    },
    [assetLayer.type, assetLayer.unit, consequencesPossible, selectedIdentifyingInformation],
  )

  const hazardUnit = useMemo(() => hazardLayer.tilesets[0]?.unit, [hazardLayer])

  const exposureDistributionTooltipFormatter = useCallback(
    (data: HistogramDataItem): ChartTooltipItem[] => {
      const values = data.value as { [key: string]: number }
      const colors = data.color as { [key: string]: string }
      return Object.keys(data.value)
        .toReversed()
        .map((key) => {
          return {
            text: consequencesPossible(key, values[key], assetLayer.unit),
            color: colors[key],
          }
        })
    },
    [assetLayer.unit, consequencesPossible],
  )
  const exposureDistributionTooltipHeaderFormatter = useCallback(
    ({
      bucketRangeStart,
      bucketRangeEnd,
    }: {
      bucketRangeStart: number
      bucketRangeEnd: number
    }) => {
      if (useCascading) return `${bucketRangeStart} days after event`
      return `Exposure: ${bucketRangeStart} - ${bucketRangeEnd}${hazardUnit ? hazardUnit : ''}`
    },
    [hazardUnit, useCascading],
  )

  //Collect charts
  const charts = []
  if (!isVulnerabilityOnlyExposed) {
    charts.push(
      <Box css={chartSection({ theme })} key="damage-state">
        <Box css={chartHeader({ theme })}>
          <Typography>Damage State{useCascading ? ' of Upstream Connections' : ''}</Typography>
        </Box>
        {vulnerabilityData.length > 0 ? (
          <Box
            css={css`
              ${chartContainer()}
              min-height: ${Math.max((vulnerabilityData.length + 1) * 40, 96)}px;
            `}
            key="damage-state-chart"
          >
            <Box css={chartSizeProtector()}>
              <Barchart
                data={vulnerabilityData}
                xTitle={xAxisTitle}
                tooltipFormatter={damageStateTooltipFormatter}
              />
            </Box>
          </Box>
        ) : (
          <EmptySection title="No damage state data" height="90%" />
        )}
      </Box>,
    )
  }
  charts.push(
    <Box
      css={css`
        ${chartSection({ theme })}
        max-width: 200px;
      `}
      key="exposure"
    >
      <Box css={chartHeader({ theme })}>
        <Typography paddingRight="4px">Percent {useCascading ? 'Impacted' : 'Exposed'}</Typography>
      </Box>
      {exposurePercentage ? (
        <Box
          css={css`
            ${chartContainer()}
            justify-content: center;
            padding: 16px 0 0 0;
            min-width: 80px;
            align-self: flex-start;
            box-sizing: border-box;

            > div {
              align-self: center;
            }
          `}
          key="exposure-chart"
        >
          <Gauge
            value={exposureTotal}
            total={assetTotal}
            unit={
              useCascading
                ? 'parcels'
                : selectedIdentifyingInformation[assetLayer.type]?.name === 'Replacement Costs'
                ? ''
                : assetLayer.unit
            }
            prefix={selectedIdentifyingInformation[assetLayer.type]?.prefix}
          />
        </Box>
      ) : (
        <EmptySection title="No exposure data" height="90%" />
      )}
    </Box>,
  )
  if (
    !isVulnerabilityOnlyExposed &&
    selectedIdentifyingInformation[assetLayer.type]?.name !== 'Replacement Costs' &&
    histogramData.length > 0
  ) {
    charts.push(
      <Box css={chartSection({ theme })} key="exposure-distribution">
        <Box css={chartHeader({ theme })}>
          <Typography>{useCascading ? 'Outage' : 'Exposure'} Distribution</Typography>
        </Box>
        <Box
          css={css`
            ${chartContainer()}
            min-height: 200px;
          `}
          key="exposure-distribution-chart"
        >
          <Box css={chartSizeProtector()}>
            <Histogram
              data={histogramData}
              bucketSize={reportRiskData?.data.bucket_size ?? 1}
              xTitle={
                useCascading
                  ? `Days since event`
                  : `Exposure${hazardUnit ? ` (${hazardUnit})` : ''}`
              }
              tooltipFormatter={exposureDistributionTooltipFormatter}
              tooltipHeaderFormatter={exposureDistributionTooltipHeaderFormatter}
              yTitle={
                useCascading ? 'Outages' : capitalizeFirstLetter(assetLayer.unit || '') + ' Exposed'
              }
            />
          </Box>
        </Box>
      </Box>,
    )
  }
  if (selectedIdentifyingInformation[assetLayer.type]?.name !== 'Replacement Costs') {
    charts.push(
      <ProjectedRisk key="projected-risk" assetLayer={assetLayer} hazardLayer={hazardLayer} />,
    )
  }

  let disclaimer = ''
  if (isVulnerabilityOnlyExposed) {
    disclaimer = `The following charts are not available due to damage data being unavailable: Damage State, Exposure Distribution, Replacement Costs. This occurs due to the quality of asset or ${
      term_preference.hazard ? 'hazard' : 'risk source'
    } information, or the lack of suitable fragility curve.`
  }

  const amILoading = selectedIdentifyingInformation[assetLayer.type]
    ? isLoading['identifyingInfo']
    : isLoading['exposure']

  return (
    <>
      <Box css={reportAssetRiskStackedSection({ shouldCompress: charts.length == 1 })}>
        <FancySuspense isLoading={amILoading} variant="text">
          {exposureTotal >= 0 && (
            <Box css={riskSummary({ theme, shouldCompress: charts.length == 1 })}>
              {assetLayer.is_cascading ? (
                <Typography>
                  {cascadingAssetNDays[assetLayer.type]} days after&nbsp;
                  {hazardLayer.scenario_name
                    ? withArticle(hazardLayer.display_name)
                    : 'this scenario'}
                  &nbsp;event, approximately {exposureTotal.toLocaleString()} parcels are expected
                  to be without&nbsp;
                  {assetLayer.display_name.charAt(0).toLowerCase() +
                    assetLayer.display_name
                      .slice(1)
                      .replace(/outage.*/, '')
                      .trim()}
                  .
                </Typography>
              ) : selectedIdentifyingInformation[assetLayer.type]?.name === 'Replacement Costs' ? (
                <Typography>
                  For&nbsp;
                  {hazardLayer.scenario_name
                    ? withArticle(hazardLayer.display_name)
                    : 'this scenario'}
                  , approximately {selectedIdentifyingInformation[assetLayer.type].prefix}
                  {roundForDisplay(exposureTotal, 2)} worth of {assetLayer.display_name} are
                  threatened.
                </Typography>
              ) : (
                <Typography>
                  For&nbsp;
                  {hazardLayer.scenario_name
                    ? withArticle(hazardLayer.display_name)
                    : 'this scenario'}
                  , approximately {exposureTotal.toLocaleString()} {assetLayer.unit}
                  {assetLayer.metric_type === 'count' ? '' : ` of ${assetLayer.display_name}`} are
                  threatened.
                </Typography>
              )}
            </Box>
          )}
        </FancySuspense>
        {assetIdentifyingInformation &&
          assetIdentifyingInformation.length > 0 &&
          replacementCostsEnabled && (
            <Box sx={{ paddingLeft: '8px', display: 'flex', gap: '12px', alignItems: 'center' }}>
              <Typography variant="caption" sx={{ color: '#0b2948', fontWeight: 'bold' }}>
                Measure consequence by:
              </Typography>
              <ToggleButtonGroup
                value={selectedIdentifyingInformation[assetLayer.type]?.property ?? 'default'}
                exclusive
                size="small"
                onChange={(_, value) => setIdentifyingInformationForAsset(assetLayer.type, value)}
              >
                {[
                  { name: 'Quantity (default)', property: 'default' },
                  ...assetIdentifyingInformation,
                ].map((parameter) => {
                  return (
                    <ToggleButton
                      key={parameter.property}
                      value={parameter.property}
                      css={css`
                        border-color: #0b2948b0;
                        padding: 4px 8px;
                        border-radius: 8px;
                        transition: background-color 0.1s;

                        &.Mui-selected {
                          background-color: #6694c320;
                        }

                        &.Mui-selected:hover {
                          background-color: #6694c330;
                        }

                        &:hover {
                          background-color: #6694c318;
                        }
                      `}
                    >
                      <Typography
                        sx={{
                          fontSize: '12px',
                          lineHeight: '16px',
                          color: '#0b2948',
                        }}
                      >
                        {parameter.name}
                      </Typography>
                    </ToggleButton>
                  )
                })}
              </ToggleButtonGroup>
            </Box>
          )}
        <FancySuspense isLoading={amILoading} variant="charts">
          <Box css={chartsContainer({ theme, shouldCompress: charts.length == 1 })}>{charts}</Box>
        </FancySuspense>
        {disclaimer && (
          <Typography css={disclaimerText({ theme })}>
            <b>Note:</b>&nbsp;{disclaimer}
          </Typography>
        )}
      </Box>
    </>
  )
})

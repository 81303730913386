import { css } from '@emotion/react'
import { alpha, Theme } from '@mui/material/styles'

export const sliderContainer = ({
  theme,
  orientation,
}: {
  theme: Theme
  orientation?: 'vertical' | 'horizontal'
}) => css`
  position: relative;
  margin-top: ${theme.spacing(-2)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${orientation === 'horizontal' && '100%'};
  height: ${orientation === 'vertical' && '100%'};
  margin-bottom: ${theme.spacing(-2)};
  & .MuiSlider-root {
    padding-top: ${theme.spacing(5)};
  }

  & .MuiSlider-mark {
    display: none;
  }

  & .MuiSlider-rail,
  .MuiSlider-track {
    height: ${orientation === 'horizontal' && '8px'};
    width: ${orientation === 'vertical' && '8px'};
  }

  & .MuiSlider-rail {
    background-color: ${theme.palette.grey[500]};
  }

  & .MuiSlider-thumb {
    height: 16px;
    width: 16px;
    box-shadow: none;

    &::before {
      opacity: 0;
    }
  }

  & .MuiSlider-valueLabel {
    color: ${theme.palette.primary.main};
    background-color: transparent;
    transform: translateY(100%) !important;
    top: 4px;
    padding: 0;
    font-size: 14px !important;
    font-weight: 800;
    padding: 0 16px;
    overflow: hidden;

    > span {
      background-color: ${theme.palette.background.paper};
      padding: 0 4px;
      box-shadow: 0 0 8px 8px ${theme.palette.background.paper};
    }
  }
`

export const slider = ({ orientation }: { orientation?: 'vertical' | 'horizontal' }) => css`
  & .MuiSlider-thumb::after {
    width: 32px;
    height: 32px;
  }

  & .MuiSlider-markLabel {
    top: ${orientation === 'horizontal' && '-15px'};
    left: ${orientation === 'vertical' && '-36px'};
  }
`

export const indicatorsContainer = css`
  position: absolute;

  left: 0px;
  right: 0px;
  top: 0;
  bottom: 0;
`

export const indicatorContainer = ({ indicatorXPosition }: { indicatorXPosition: number }) => css`
  position: absolute;
  left: calc(${indicatorXPosition}%);
  top: 40px;
`

export const triangleTick = () => css`
  position: absolute;
  top: 0px;
  transform: scaleX(1.2);

  &.MuiTypography-root {
    font-size: 13px !important;
  }
`

export const tickValue = ({
  theme,
  index,
  totalTicks,
  disabled,
}: {
  theme: Theme
  index: number
  totalTicks: number
  disabled?: boolean
}) => {
  const visible = index === 0 || index === totalTicks - 1 || index === Math.floor(totalTicks / 2)

  return css`
    position: absolute;
    top: 16px;
    left: 0;
    transform: translate(-50%, 0);
    color: ${disabled ? alpha(theme.palette.text.disabled, 0.5) : theme.palette.text.primary};
    &.MuiTypography-root {
      font-size: 14px !important;
    }
    visibility: ${visible ? 'visible' : 'hidden'};
  `
}

export const tickContainer = css`
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: -12px;
`

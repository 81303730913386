import { MAX_CHART_DECIMALS, cleanFloatingPointErrors } from './utils'

export class AxisCalculator {
  domain: [number, number]
  decimal_places: number
  tickCount: number

  constructor(numbers: number[], maxTicks = 6) {
    numbers = numbers.map((num) => cleanFloatingPointErrors(num))

    const decimals = numbers.map((num) => {
      const parts = num.toString().split('.')
      return parts.length > 1 ? parts[1].length : 0
    })

    this.decimal_places = Math.min(MAX_CHART_DECIMALS, Math.max(...decimals))

    const { min, max, bucketSize } = roundOutBounds(numbers, maxTicks)
    this.domain = [min, max]

    this.tickCount = Math.ceil((max - min) / bucketSize) + 1
  }
  roundValue = (value: number) => {
    const roundedValue = cleanFloatingPointErrors(value)
    const absValue = Math.abs(roundedValue)
    let result = ''

    if (absValue >= 1e12) {
      result = (roundedValue / 1e12).toFixed(this.decimal_places) + 'T'
    } else if (absValue >= 1e9) {
      result = (roundedValue / 1e9).toFixed(this.decimal_places) + 'B'
    } else if (absValue >= 1e6) {
      result = (roundedValue / 1e6).toFixed(this.decimal_places) + 'M'
    } else {
      result = roundedValue.toLocaleString(undefined, {
        maximumFractionDigits: this.decimal_places,
        minimumFractionDigits: this.decimal_places,
      })
    }

    return result
  }

  roundValueForDisplay = (value: number) => {
    const result = this.roundValue(value)

    // Show for example "<0.01" when dp is 2 and value > 0 and result is "0.00"
    if (value > 0) {
      if (result === '0') {
        return '<1'
      }
      if (result.match(/^0\.0+$/)) {
        return `<0.${'0'.repeat(this.decimal_places - 1)}1`
      }
    }

    return result
  }
}

const roundOutBounds = (numbers: number[], maximumBuckets = 6) => {
  const min = Math.min(...numbers)
  const max = Math.max(...numbers)
  const minBucketSize = (max - min) / maximumBuckets
  let bucketSize = minBucketSize

  for (let exponent = -8; exponent < 16; exponent++) {
    const power = 10 ** exponent

    for (const friendlyMultiple of [1, 2, 5]) {
      if (minBucketSize < power * friendlyMultiple) {
        bucketSize = power * friendlyMultiple
        break
      }
    }

    if (bucketSize !== minBucketSize) {
      break
    }
  }

  const minBound = Math.floor(min / bucketSize) * bucketSize
  const maxBound = Math.ceil(max / bucketSize) * bucketSize
  return { min: minBound, max: maxBound, bucketSize }
}

import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const hazardControlShownScenario = ({ theme }: { theme: Theme }) => css`
  min-width: 200px;
  background-color: #f3f3f3;
  padding: ${theme.spacing(2)};
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .no-filter {
    font-size: 12px;
    padding-top: 4px;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    margin-top: -2px;
    color: ${theme.palette.text.primary};
    font-style: italic;
    line-height: 1.2;
  }
`

export const scenarioHeader = css`
  font-size: 12px;
  font-weight: 700;
`

export const scenarioParameterBox = css`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 12px 0;
  flex-direction: column;
`

export const scenarioParameterBoxHeader = css`
  display: flex;
  align-items: center;
  p {
    font-size: 12px;
    font-weight: 700;
  }

  > button {
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const scenarioParameterBoxContent = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: stretch;
  flex-direction: column;

  p {
    line-height: 1.3;
  }

  p.big-value {
    font-size: 23px;
    font-weight: 800;
    color: ${theme.palette.primary.main};
  }

  p.small-value {
    font-size: 12px;
    font-weight: 500;
    color: ${theme.palette.primary.main};
  }
`

/** @jsxImportSource @emotion/react */
import { Expression, Map, FillLayer } from 'mapbox-gl'
import { byAssetIdIn } from '../RiskMapView.utilities'
import { MapLayerStyle } from '@contexts/MapContext'

export interface VectorLayerProps {
  map: Map
  layerStyle: MapLayerStyle
  id: string
  beforeId: string
  isVisible: boolean
  sourceLayer: string
  colorExpression: Expression
  fillOpacityExpression: Expression
  assetIds?: { [asset_id: number]: boolean }
  sortKeyExpression?: Expression
  hasFilters: boolean
  shouldUpdate?: boolean
}

export const addVectorLayer = ({
  map,
  layerStyle,
  id,
  beforeId,
  isVisible,
  sourceLayer,
  colorExpression,
  fillOpacityExpression,
  assetIds,
  sortKeyExpression,
  hasFilters,
  shouldUpdate = false,
}: VectorLayerProps) => {
  const fillLayerData: FillLayer = {
    id,
    type: 'fill',
    source: id,
    'source-layer': sourceLayer,
    layout: {
      visibility: isVisible ? 'visible' : 'none',
      'fill-sort-key': sortKeyExpression || 0,
    },
    paint: {
      'fill-opacity': fillOpacityExpression,
      ...layerStyle,
    },
  }

  if (shouldUpdate) {
    if (fillLayerData.paint) {
      for (const paintProp of Object.entries(fillLayerData.paint)) {
        map.setPaintProperty(fillLayerData.id, paintProp[0], paintProp[1])
      }
    }
    if (fillLayerData.layout) {
      for (const layoutProp of Object.entries(fillLayerData.layout)) {
        map.setLayoutProperty(fillLayerData.id, layoutProp[0], layoutProp[1])
      }
    }
    if (!hasFilters && !assetIds) map.setFilter(id, null)
  } else {
    map.addSource(id, { type: 'vector', url: `mapbox://${id}` })

    map.addLayer(fillLayerData, beforeId)
  }
  if (hasFilters && assetIds) {
    const visibilityExpression: Expression = ['boolean', byAssetIdIn(assetIds)]
    map.setFilter(id, visibilityExpression)
  }
  if (colorExpression) {
    map.setPaintProperty(id, 'fill-color', colorExpression)
  }
}

import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const informativePanel = css`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;

  .tutorial-sidedrawer {
    position: absolute;
    inset: 8px;
    pointer-events: none;
    user-select: none;
  }
`

export const layerDetailsHeaderTitle = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${theme.spacing(2)};
  color: ${theme.palette.primary.dark};
`

export const layerDetailsHeaderIcon = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.primary.dark};
`

export const layerDetailsHeaderTabs = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${theme.spacing(2)};
`

export const layerDetailsHeaderTab = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing(1)} ${theme.spacing(4)};
  border-radius: 4px;
  color: ${theme.palette.primary.main};
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  position: relative;
  white-space: nowrap;
`

export const layerDetailsHeaderTabCount = ({ theme }: { theme: Theme }) => css`
  border: 1px solid ${theme.palette.primary.dark};
  color: ${theme.palette.primary.dark};
  font-size: 0.8rem;
  width: 1.4em;
  height: 1.4em;
  top: 4px;
  right: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`

export const infoPanelTabs = ({ theme }: { theme: Theme }) => css`
  border: none;
  font-size: 1rem;
  max-height: 100%;
  height: 100%;
  flex-grow: 1;

  > div:first-of-type {
    border-bottom: none;
    padding-top: 1px;
  }

  div[role='tabpanel'] {
    overflow-y: auto;
    padding: ${theme.spacing(1)};
    background-color: ${theme.palette.primary.main}20;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &[hidden] {
      display: none;
    }

    &::-webkit-scrollbar {
      width: 12px !important;
    }

    &::-webkit-scrollbar-track {
      background-color: ${theme.palette.primary.main}00 !important;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.primary.main}40 !important;
      border-radius: 0px !important;
    }
  }

  .MuiTabs-root {
    box-shadow: 0 0 4px 0 ${theme.palette.primary.dark}40;
    position: relative;
    z-index: 1;
  }

  button[role='tab'] {
    font-size: 0.9rem;
    letter-spacing: 0.8px;
    font-weight: 700;

    &[aria-selected='true'] {
      color: ${theme.palette.primary.dark};
    }
  }

  .MuiTabs-indicator {
    background-color: ${theme.palette.primary.dark}AA;
    border-radius: 4px 4px 0 0;
  }
`

export const informativePanelMoreMenuButton = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  top: 0;
  right: 0;
  color: grey;
  width: ${theme.spacing(12)};
  padding: 0;
  height: ${theme.spacing(12)};
  color: ${theme.palette.grey[600]};
`

export const informativePanelMoreMenu = ({ theme }: { theme: Theme }) => css`
  .MuiMenu-paper {
    box-shadow: 0 4px 4px -2px ${theme.palette.primary.dark}40;
    border-radius: 0 0 4px 4px;
    border-top: 1px solid ${theme.palette.primary.dark}20;
    color: ${theme.palette.grey[700]};
  }
`

export const tabContent = css`
  margin: -4px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const infoPanelFooter = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  box-shadow: 0 1px 4px 0 ${theme.palette.primary.dark}80;
  z-index: 1;
  position: relative;

  .tutorial-summary-report-button {
    position: absolute;
    inset: 5px;
    pointer-events: none;
    user-select: none;
    overflow: hidden;
    max-height: 40px;
    min-height: 40px;
    align-self: flex-end;
  }
`

export const tabsContainer = css`
  flex-grow: 1;
  overflow: hidden;
`

export const addLayersFab = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  bottom: ${theme.spacing(4)};
  right: ${theme.spacing(4)};
  background-color: ${theme.palette.primary.main};
  color: white;
  z-index: 1;
  height: ${theme.spacing(16)};
  width: ${theme.spacing(16)};
  border-radius: ${theme.spacing(14)};
  transition: width 0.2s ease-in-out, background-color 0.2s ease-in-out;
  display: flex;
  overflow: hidden;

  svg {
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
  }

  .text {
    width: 0;
    white-space: nowrap;
    opacity: 0;
    padding: 0;
    transition: width 0.2s ease-in-out, opacity 0.2s ease-in-out, padding 0.2s ease-in-out;
  }

  &:hover {
    background-color: ${theme.palette.primary.dark};
    width: ${theme.spacing(44)};

    .text {
      width: ${theme.spacing(40)};
      opacity: 1;
      padding: 0 ${theme.spacing(2)};
    }
  }
`

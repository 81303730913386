/** @jsxImportSource @emotion/react */

import { LayerControlButtons } from '../LayerControlButtons'
import { Box, Typography, useTheme } from '@mui/material'
import { accordionHazards } from '..'
import {
  accordionHazardContentContainer,
  hiddenLayerContainer,
  hiddenLayerLabel,
  layerHeader,
} from './LayerAccordionList.styles'
import { MapLayer, MapLayerType } from '@redux/map/mapSlice'
import { DialogLayersPreviewProps } from '@src/components/Molecules/MapView/AddLayersDialog/LayersPreview'
import { Accordion } from '@src/components/Atoms/Accordion'
import { LayerInfoAccordions } from '../../InfoAccordions'
import { useEffect, useMemo, useRef, useState } from 'react'
import { SortableList } from '@src/components/Atoms/Sortable'
import { usePreferences } from '@contexts/PreferencesContext'
import { LayerDetailsReport } from '@src/components/Molecules/SideDrawerContent/data_fetchers/layerDetailsFetcher'

export const LayerAccordionList = function LayerAccordList({
  layers,
  layerDetailsList,
  handleUpdateLayer,
  dialogProps,
  isDialogPreview,
  handleDeleteLayer,
}: {
  layers: MapLayer[]
  layerDetailsList: LayerDetailsReport[][] | null
  handleUpdateLayer: ({
    parentLayerId,
    newLayers,
  }: {
    parentLayerId: MapLayerType
    newLayers: MapLayer[]
  }) => void
  dialogProps?: DialogLayersPreviewProps
  isDialogPreview: boolean
  handleDeleteLayer: (props: { selectedLayer: MapLayer }) => void
}) {
  const theme = useTheme()
  const { use_hazard_name } = usePreferences()

  const parentLayerId = useMemo(() => layers[0].layerType, [layers])
  const triggeredUpdate = useRef(false)

  const [items, setItems] = useState<MapLayer[]>([])

  useEffect(() => {
    if (triggeredUpdate.current) {
      triggeredUpdate.current = false
      return
    }
    setItems(
      layers.map((layer) => {
        return { ...layer, id: layer.tilesets.map((tileset) => tileset.id).join('-') }
      }),
    )
  }, [layers])

  return (
    <SortableList
      items={items}
      onChange={(newLayers) => {
        triggeredUpdate.current = true
        setItems(newLayers)
        handleUpdateLayer({ parentLayerId, newLayers })
      }}
      renderItem={(layer, _, _isDragging) => {
        const layerDetails = layerDetailsList
          ? layerDetailsList.find(
              (detailReport) =>
                detailReport &&
                detailReport.find(
                  (detail) => detail.layer_id === layer.type || detail.layer_id === layer.assetTag,
                ),
            ) ?? null
          : null
        const canExpand =
          (layerDetails && layerDetails.length > 0 && !isDialogPreview) ||
          (!!layer.filterable_information && !isDialogPreview && !!layerDetails) ||
          false

        return (
          <SortableList.Item key={layer.id} id={layer.id}>
            <Accordion
              css={accordionHazards({ theme })}
              defaultExpanded={false}
              lazyContent
              variant={'outline'}
              level={'h3'}
              isDraggable
              accordionStateKey={canExpand ? `layer-accordion-list-${layer.id}` : undefined}
              header={
                <Box css={layerHeader({ canExpand })}>
                  <Box css={hiddenLayerContainer({ theme })}>
                    <Typography
                      css={hiddenLayerLabel({
                        theme,
                        isVisible: layer.visible,
                      })}
                    >
                      {use_hazard_name.flag
                        ? layer.hazard_name ?? layer.display_name
                        : layer.display_name}
                    </Typography>
                  </Box>

                  <LayerControlButtons
                    layer={layer}
                    layerDetails={layerDetails}
                    dialogProps={dialogProps}
                    isDialogPreview={isDialogPreview}
                    handleDeleteLayer={handleDeleteLayer}
                  />
                </Box>
              }
              body={
                canExpand ? (
                  <Box css={accordionHazardContentContainer({ theme })}>
                    <Box key={layer.id}>
                      <LayerInfoAccordions
                        layer={layer}
                        layerType={layer.layerType}
                        layerDetails={layerDetails}
                      />
                    </Box>
                  </Box>
                ) : undefined
              }
            />
          </SortableList.Item>
        )
      }}
    />
  )
}

/** @jsxImportSource @emotion/react */
import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  useTheme,
  Typography,
} from '@mui/material'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

import { MapLayer, MapLayerType } from '@redux/map/mapSlice'
import { BASE_API_URL } from '@src/app-constants'
import { Icon } from '@src/components/Atoms/Icon'
import {
  IconTypography,
  MoreInformationModal,
  Tooltip,
  firstLetterToUpperCase,
} from '@uintel/ui-component-library'
import {
  informationLayersContainer,
  informationLayerContent,
  informationInternalAccordionIcon,
  informationLayersFormAccordions,
  informationLayersFormInternalAccordion,
} from './InformationLayersForm.styles'
import { Clear } from '@mui/icons-material'
import { InformationDialogData } from '../AddLayersDialog.types'
import { UiclAccordion } from '@src/components/Atoms/Accordion/UiclAccordion'

export type InformationLayersFormProps = {
  selectedInformation: MapLayer[]
  informationDialogData: InformationDialogData
  onSelectInformationLayer: (informationLayer: MapLayer) => void
  onRemoveLayer: (layer: MapLayer, type: MapLayerType) => void
}

const flattenInfoData = (passedInfoData: InformationDialogData): MapLayer[] => {
  const toReturn: MapLayer[] = []
  const handledIds: { [key: string]: boolean } = {}

  Object.keys(passedInfoData).map((infoMenuKey) => {
    if (!(infoMenuKey in passedInfoData)) return
    if (!('layers' in passedInfoData[infoMenuKey])) return
    if (!passedInfoData[infoMenuKey].layers.length) return

    passedInfoData[infoMenuKey].layers.map((infoDetail) => {
      if (handledIds[infoDetail.tilesets[0].id]) return

      toReturn.push(infoDetail)
      handledIds[infoDetail.tilesets[0].id] = true
    })
  })

  toReturn.sort()
  return toReturn
}

export const InformationLayersForm = ({
  selectedInformation,
  informationDialogData,
  onSelectInformationLayer,
  onRemoveLayer,
}: InformationLayersFormProps) => {
  const theme = useTheme()
  const flatInfoList = flattenInfoData(informationDialogData)
  const [searchTerm, setSearchTerm] = useState('')
  const [informationDetailsModal, setInformationDetailsModal] = useState({
    isOpen: false,
    helpFileName: '',
  })

  // filters flatInfoList and searchTerm to only show the contextual layers that match the search term
  const searchedInfoLayers = useMemo(() => {
    return flatInfoList.filter((infoLayer) => {
      return infoLayer.display_name.toLowerCase().includes(searchTerm.toLowerCase())
    })
  }, [flatInfoList, searchTerm])

  const isInfoLayerSelected = (selectedInformationLayer: MapLayer) => {
    const isAlreadySelected = selectedInformation.some(
      (infoLayer) => infoLayer.id === selectedInformationLayer.id,
    )
    return isAlreadySelected
  }

  const handleAddInformationLayer = (informationLayer: MapLayer) => {
    const foundInfoLayers = selectedInformation.find((infoLayer) => {
      return infoLayer.id === informationLayer.id
    })
    if (foundInfoLayers) {
      onRemoveLayer(informationLayer, 'information')
      return
    }
    const formattedInformationLayer = {
      ...informationLayer,
      visible: true,
      interactivityDisabled: false,
      layerType: 'information' as MapLayerType,
    }
    onSelectInformationLayer(formattedInformationLayer)
  }

  if (!flatInfoList.length && searchedInfoLayers.length === 0) {
    return (
      <Typography variant="subtitle2" align="center" marginTop={1}>
        No contextual layers available
      </Typography>
    )
  }

  return (
    <>
      <Box css={informationLayersContainer({ theme })}>
        <TextField
          css={{ margin: `0 ${theme.spacing(1)}` }}
          label="Search"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value)
          }}
          InputProps={{
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  edge="end"
                  onClick={() => {
                    setSearchTerm('')
                  }}
                >
                  <Clear />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box css={informationLayerContent()}>
          {searchTerm.length === 0 ? (
            <Box css={informationLayersFormAccordions({ theme })}>
              {Object.keys(informationDialogData).map((informationMenuKey) => (
                <UiclAccordion
                  key={informationMenuKey}
                  defaultExpanded
                  header={
                    <IconTypography icon={informationDialogData[informationMenuKey].icon}>
                      {informationDialogData[informationMenuKey].title}
                    </IconTypography>
                  }
                  body={
                    <Box css={informationLayersFormInternalAccordion({ theme })}>
                      {informationDialogData[informationMenuKey].layers.map((information) => {
                        return (
                          <ListItemButton
                            selected={isInfoLayerSelected(information)}
                            key={information.tilesets[0].id}
                            onClick={() => handleAddInformationLayer(information)}
                          >
                            <ListItemIcon css={informationInternalAccordionIcon}>
                              <IconTypography
                                icon={firstLetterToUpperCase(information.icon || '') as Icon}
                              />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ paddingLeft: theme.spacing(1) }}
                              primary={information.display_name}
                            />
                            <ListItemIcon css={informationInternalAccordionIcon}>
                              {information.helpFileName && (
                                <Tooltip title={information.display_name}>
                                  <IconButton
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      setInformationDetailsModal({
                                        isOpen: true,
                                        helpFileName: information.helpFileName,
                                      })
                                    }}
                                  >
                                    <Icon
                                      iconName="Info"
                                      colour={theme.palette.grey[500]}
                                      size="small"
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </ListItemIcon>
                          </ListItemButton>
                        )
                      })}
                    </Box>
                  }
                ></UiclAccordion>
              ))}
            </Box>
          ) : (
            // iterate over searchedInfoLayers without an accordion
            <List disablePadding>
              {searchedInfoLayers.map((information) => {
                return (
                  <ListItemButton
                    selected={isInfoLayerSelected(information)}
                    key={information.tilesets[0].id}
                    onClick={() => handleAddInformationLayer(information)}
                  >
                    <ListItemIcon css={informationInternalAccordionIcon}>
                      <IconTypography icon={firstLetterToUpperCase(information.icon) as Icon} />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ paddingLeft: theme.spacing(1) }}
                      primary={information.display_name}
                    />
                    <ListItemIcon css={informationInternalAccordionIcon}>
                      {information.helpFileName && (
                        <Tooltip title={information.display_name}>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation()
                              setInformationDetailsModal({
                                isOpen: true,
                                helpFileName: information.helpFileName,
                              })
                            }}
                          >
                            <Icon iconName="Info" colour={theme.palette.grey[500]} size="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                )
              })}
            </List>
          )}
        </Box>
      </Box>
      {informationDetailsModal.isOpen &&
        createPortal(
          <MoreInformationModal
            baseURL={BASE_API_URL}
            helpFileName={informationDetailsModal.helpFileName}
            isOpen={informationDetailsModal.isOpen}
            handleClose={() =>
              setInformationDetailsModal({
                isOpen: false,
                helpFileName: '',
              })
            }
          />,
          document.body,
        )}
    </>
  )
}

/** @jsxImportSource @emotion/react */
import { MapLayer } from '@redux/map/mapSlice'
import { LayerInfoContent } from './LayerInfoContent'
import { Box, useTheme } from '@mui/material'
import { layerDetailsContainer, layerDetailsHeader } from './LayerInfoAccordions.styles'
import { Icon } from '@src/components/Atoms/Icon'
import { LayerDetailsReport } from '../../../../data_fetchers/layerDetailsFetcher'
import { EmptySection } from '../../../../components/EmptySection'
import { ElementFilterForm } from '@src/components/Molecules/LayersControl/ElementFilterForm/ElementFilterForm'
import { useFeatureFlags } from '@contexts/FeatureFlagsContext'

interface InfoAccordionsProps {
  layer: MapLayer
  layerType: string
  defaultExpanded?: boolean
  layerDetails: LayerDetailsReport[] | null
}

export const LayerInfoAccordions = ({ layer, layerDetails }: InfoAccordionsProps) => {
  const theme = useTheme()

  const { features } = useFeatureFlags()
  const attributeFiltersFeature = features.find(
    (feature) => feature.feature == 'attributeFilters' && feature.enabled,
  )

  if (!layerDetails && !layer.filterable_information)
    return (
      <EmptySection
        title={`Currently there's no additional information for ${layer.display_name}`}
      />
    )

  return (
    <Box css={layerDetailsContainer({ theme })}>
      {layerDetails?.map((layerDetail, index) => (
        <Box key={index}>
          {attributeFiltersFeature && <ElementFilterForm layer={layer} />}

          <Box css={layerDetailsHeader({ theme })}>
            <Icon iconName={layerDetail.icon} size="medium" colour="primary" />
            <h4>{layerDetail.title}</h4>
          </Box>
          <LayerInfoContent layerDetail={layerDetail} />
        </Box>
      ))}
    </Box>
  )
}

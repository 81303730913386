import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataPoint } from '@src/components/Molecules/Charts/ProjectedRiskChart/ProjectedRiskChart'

export interface ProjectedRiskChartState {
  [element: string]: {
    [risk: string]: {
      assetId: string
      assetUnit?: string
      data: DataPoint[]
      parameter: string
      parameterUnit: string
      vulnerabilityList: string[]
      parameterTitle: string
      secondaryParameter: string | null
      secondaryParameterValue: number | string | null
    }
  }
}

const initialState: ProjectedRiskChartState = {}

export const projectedRiskChartStateSlice = createSlice({
  name: 'projectedRiskChartStateSlice',
  initialState,
  reducers: {
    setProjectedRiskChartState: (
      state,
      action: PayloadAction<{
        element: string
        risk: string
        projectedRiskChartStateParameters: {
          assetId: string
          assetUnit?: string
          data: DataPoint[]
          parameter: string
          parameterUnit: string
          vulnerabilityList: string[]
          parameterTitle: string
          secondaryParameter: string | null
          secondaryParameterValue: number | string | null
        }
      }>,
    ) => {
      if (!state[action.payload.element]) state[action.payload.element] = {}
      state[action.payload.element][action.payload.risk] =
        action.payload.projectedRiskChartStateParameters

      return state
    },
  },
})

export const { setProjectedRiskChartState } = projectedRiskChartStateSlice.actions
export const projectedRiskChartStateReducer = projectedRiskChartStateSlice.reducer

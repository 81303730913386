import { PUBLIC_ASSETS_BASE_URL } from '@src/app-constants'

export function capitalizeFirstLetter(string: string) {
  return string.trim().charAt(0).toUpperCase() + string.trim().slice(1)
}

export function generateS3Url(src: string) {
  const lowerCaseSrc = src.toLocaleLowerCase()

  if (lowerCaseSrc.startsWith('s3://')) {
    const iconIdS3 = lowerCaseSrc.replace('s3://', '').replace('.svg', '')
    return `${PUBLIC_ASSETS_BASE_URL}/icons/${iconIdS3}.svg`
  }

  return src
}

export const englishList = (items: string[]): string => {
  if (items.length == 0) return ''
  if (items.length == 1) return items[0]

  // Replace for example: "Property boundaries, Marae, and Industrial properties and facilities"
  // with: "Property boundaries, Marae, and Industrial properties & facilities"
  const ampersandedItems = items.map((item) => item.replace(' and ', ' & '))

  const lastItem = ampersandedItems.pop()
  if (ampersandedItems.length == 1) return `${ampersandedItems[0]} and ${lastItem}`

  return `${ampersandedItems.join(', ')}, and ${lastItem}`
}

export function truncateToNearestSpace(text: string, maxLength: number): string {
  if (text.length > maxLength) {
    const truncatedText = text.substring(0, maxLength)
    const lastSpacePos = truncatedText.lastIndexOf(' ')
    if (lastSpacePos > 0) return truncatedText.substring(0, lastSpacePos) + '...'
    return truncatedText + '...'
  }
  return text
}

/** @jsxImportSource @emotion/react */

import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Tabs, Tab } from '@uintel/ui-component-library'

import { SummaryPageContainer } from './SummaryPage.styles'
import { useFeatureFlags } from '@contexts/FeatureFlagsContext'
import { SummaryPagePlaceHolder } from '../SummaryPagePlaceHolder'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { useCallback, useEffect, useState } from 'react'
import axios from '@src/utils/customAxios'
import { BASE_API_URL } from '@src/app-constants'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'

export const SummaryPage = () => {
  const theme = useTheme()
  const { clientName } = useSelector((state: RootState) => state.user)
  const [pageContent, setPageContent] = useState<{ section: string; content: string }[]>([])
  const [tabs, setTabs] = useState<Tab[]>([])
  const { features } = useFeatureFlags()
  const pageEnabled = features.find(
    (feature) =>
      feature.feature == 'summaryPage' &&
      feature.enabled &&
      clientName &&
      clientName !== 'openaccess',
  )

  useEffect(() => {
    if (!clientName) return
    const abortController = new AbortController()
    const fetchRegions = async () => {
      try {
        const response = await axios.get(`${BASE_API_URL}/api/content/summaryPage`, {
          signal: abortController.signal,
        })

        setPageContent(response.data)
      } catch (error) {
        setPageContent([])
      }
    }
    fetchRegions()
    return () => {
      abortController.abort()
    }
  }, [clientName])

  const renderTabContent = useCallback((content: string) => {
    return <UiMarkdown>{content}</UiMarkdown>
  }, [])

  useEffect(() => {
    if (!pageContent) return
    const newTabs: Tab[] = pageContent.map((item) => ({
      label: item.section,
      value: item.section,
      content: renderTabContent(item.content),
    }))
    setTabs(newTabs)
  }, [pageContent, renderTabContent])

  return (
    <>
      {pageEnabled ? (
        <Box css={SummaryPageContainer({ theme })}>
          <Tabs tabs={tabs} />
        </Box>
      ) : (
        <SummaryPagePlaceHolder />
      )}
    </>
  )
}

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { MapLayer, DrawArea } from '@redux/map/mapSlice'
import { RootState } from '@redux/store'
import { useLayers } from '@contexts/LayerContext'
import { useMap } from '@contexts/MapContext'
import { VisibleAssets } from '@src/components/Molecules/MapView/RiskMapView.utilities'
import { Polygon } from 'geojson'
import { BASE_API_URL } from '@src/app-constants'
import axios from '@src/utils/customAxios'
import { useSelector } from 'react-redux'

const potentialIdentifyingInformation = [
  {
    name: 'Replacement Costs',
    property: 'replacement_cost',
    prefix: '$',
    suffix: '',
  },
]

export type VulnBucket = {
  total: number
  low_vulnerability: number
  medium_vulnerability: number
  high_vulnerability: number
  potential_vulnerability: number
  exposed_vulnerability: number
  unspecified_vulnerability: number
  insignificant_vulnerability: number
}

export interface AssetHazardExposureStats {
  total_metric: number
  bucket_size: number
  exposure_buckets: ({
    exposure_bucket: string
  } & VulnBucket)[]
  n_days_buckets: ({
    n_days: number
  } & VulnBucket)[]
  totals: VulnBucket
}

export interface RiskData {
  asset_type: string
  assetDisplayName: string
  hazard_scenario: string
  hazardScenarioDisplayName: string
  data: AssetHazardExposureStats
  attribute_stats: AssetAttributeStats
}

export interface AssetAttributeStats {
  replacement_costs: AssetStats
}

export interface AssetStats {
  total: MinMaxValue
  potential: MinMaxValue
  insignificant: MinMaxValue
  low: MinMaxValue
  medium: MinMaxValue
  high: MinMaxValue
  exposed: MinMaxValue
  unspecified: MinMaxValue
}

export interface MinMaxValue {
  min?: number
  value: number
  max?: number
}

export const fetchIdentifyingInformationAssetExposureStats = async (
  assetLayers: MapLayer[],
  hazardLayers: MapLayer[],
  attribute: string,
  regions: string[],
  drawAreas: DrawArea[],
  visibleAssets?: VisibleAssets,
): Promise<RiskData[]> => {
  try {
    const assetLayerTypes = assetLayers.map((assetLayer) => assetLayer.type)
    const hazardLayerAssetTags = hazardLayers.map((hazardLayer) => hazardLayer.assetTag)
    const query = [`attribute=${attribute}`]
    if (regions.length > 0) query.push(`regions=${regions.join(',')}`)
    const drawAreaCoordinateArray: Polygon | null =
      drawAreas.length > 0 ? (drawAreas[0].geometry as Polygon) : null
    const drawAreaCsv = drawAreaCoordinateArray
      ? drawAreaCoordinateArray.coordinates[0].flat().join(',')
      : ''

    const mappedVisibleAssets = visibleAssets
      ? Object.keys(visibleAssets).reduce((acc, assetType) => {
          acc[assetType] = Object.keys(visibleAssets[assetType])
          return acc
        }, {} as { [key: string]: string[] })
      : visibleAssets

    const url = `${BASE_API_URL}/api/asset/exposure/identifying_information_stats/${assetLayerTypes.join(
      ',',
    )}/${hazardLayerAssetTags.join(',')}/${drawAreaCsv}?${query.join('&')}`

    const response = await axios.post<RiskData[]>(url, {
      prefiltered_asset_ids: mappedVisibleAssets,
    })

    return response.data
  } catch (error) {
    return []
  }
}

export const fetchAssetExposureStats = async (
  assetLayers: MapLayer[],
  hazardLayers: MapLayer[],
  regions: string[],
  drawAreas: DrawArea[],
  visibleAssets?: VisibleAssets,
): Promise<RiskData[]> => {
  try {
    const assetLayerTypes = assetLayers.map((assetLayer) => assetLayer.type)
    const hazardLayerAssetTags = hazardLayers.map((hazardLayer) => hazardLayer.assetTag)
    const query = []
    if (regions.length > 0) query.push(`regions=${regions.join(',')}`)
    const drawAreaCoordinateArray: Polygon | null =
      drawAreas.length > 0 ? (drawAreas[0].geometry as Polygon) : null
    const drawAreaCsv = drawAreaCoordinateArray
      ? drawAreaCoordinateArray.coordinates[0].flat().join(',')
      : ''

    const mappedVisibleAssets = visibleAssets
      ? Object.keys(visibleAssets).reduce((acc, assetType) => {
          acc[assetType] = Object.keys(visibleAssets[assetType])
          return acc
        }, {} as { [key: string]: string[] })
      : visibleAssets

    const url = `${BASE_API_URL}/api/asset/exposure/stats/${assetLayerTypes.join(
      ',',
    )}/${hazardLayerAssetTags.join(',')}/${drawAreaCsv}?${query.join('&')}`

    const response = await axios.post<RiskData[]>(url, {
      prefiltered_asset_ids: mappedVisibleAssets,
    })

    return response.data
  } catch (error) {
    return []
  }
}

export const fetchCheckIfIdentifyingInformationExists = async (
  attribute: string,
  assetLayers: MapLayer[],
): Promise<{ asset_type: string; has_identifying_information: boolean }[]> => {
  if (assetLayers.length === 0) return []
  try {
    const assetLayerTypes = assetLayers.map((assetLayer) => assetLayer.type)

    const url = `${BASE_API_URL}/api/asset/check_identifying_information_exists/${assetLayerTypes.join(
      ',',
    )}/?attribute=${attribute}`

    const response = await axios.post<
      { asset_type: string; has_identifying_information: boolean }[]
    >(url)

    return response.data
  } catch (error) {
    return []
  }
}

type IdentifyingInformation = {
  name: string
  property: string
  prefix?: string
  suffix?: string
}

interface RiskFetcherContextProps {
  riskToAssets: { [key: string]: RiskData[] }
  isLoading: { [key: string]: boolean }
  availableIdentifyingInformation: { [key: string]: IdentifyingInformation[] }
  setIdentifyingInformationForAsset: (assetType: string, attribute: string) => void
  selectedIdentifyingInformation: { [key: string]: IdentifyingInformation }
}

export const RiskFetcherContext = createContext<RiskFetcherContextProps>({
  riskToAssets: {},
  isLoading: {},
  availableIdentifyingInformation: {},
  setIdentifyingInformationForAsset: () => {
    return
  },
  selectedIdentifyingInformation: {},
})

export function RiskFetcherProvider({ children }: { children: React.ReactNode }) {
  const [reportRiskDetailData, setReportRiskDetailData] = useState<{ [key: string]: RiskData[] }>(
    {},
  )
  const [availableIdentifyingInformation, setAvailableIdentifyingInformation] = useState<{
    [key: string]: { name: string; property: string }[]
  }>({}) // key is the asset type, value is the list of attributes

  const [isLoading, setIsLoadingState] = useState<{ [key: string]: boolean }>({})
  const setIsLoading = (key: string, loading: boolean) => {
    setIsLoadingState((prev) => {
      return { ...prev, [key]: loading }
    })
  }

  const { visibleAssets, elementFilters } = useLayers()

  const [cachedRiskData, setCachedRiskData] = useState<{ [key: string]: RiskData[] }>({})
  const [cachedAvailableIdentifyingInformation, setCachedAvailableIdentifyingInformation] =
    useState<{
      [key: string]: { [key: string]: { name: string; property: string }[] }
    }>({})
  const [cachedIdentifyingInformationRiskData, setCachedIdentifyingInformationRiskData] = useState<{
    [key: string]: RiskData[]
  }>({})

  const [selectedIdentifyingInformation, setSelectedIdentifyingInformation] = useState<{
    [key: string]: IdentifyingInformation
  }>({})

  const { regionMasks, cascadingAssetNDays } = useMap()
  const mapStore = useSelector((state: RootState) => state.map)

  const { drawAreas, layers } = mapStore

  const memoizedFilteredData = useCallback(async () => {
    const layerIds = layers.map((layer) => layer.type ?? layer.assetTag ?? layer.id)
    if (layerIds.length < 2) return []
    const cacheKey = JSON.stringify({
      layerIds,
      regionMasks: regionMasks ? regionMasks.map((mask) => mask.region) : [],
      drawAreas,
      cascadingAssetNDays,
      elementFilters,
    })
    if (cachedRiskData[cacheKey]) {
      return cachedRiskData[cacheKey]
    }

    const assetLayers = layers.filter((layer) => layer.layerType === 'asset')
    const hazardLayers = layers.filter((layer) => layer.layerType === 'hazard')
    const hasElementFilters = Object.values(elementFilters).some(
      (filters) => Object.keys(filters).length > 0,
    )

    const data = await fetchAssetExposureStats(
      assetLayers,
      hazardLayers,
      regionMasks ? regionMasks.map((mask) => mask.region) : [],
      drawAreas,
      hasElementFilters ? visibleAssets : undefined,
    )

    cachedRiskData[cacheKey] = data
    setCachedRiskData((prev) => {
      return { ...prev, [cacheKey]: data }
    })

    return data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers, cascadingAssetNDays, visibleAssets])

  const setIdentifyingInformationForAsset = useCallback((assetType: string, attribute: string) => {
    setSelectedIdentifyingInformation((prev) => {
      if (attribute === 'default') {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [assetType]: _, ...rest } = prev
        return rest
      }
      const attributeObject = potentialIdentifyingInformation.find((i) => i.property === attribute)
      if (!attributeObject) return prev
      return {
        ...prev,
        [assetType]: attributeObject,
      }
    })
  }, [])

  const memoizedAvailableIdentifyingInformation = useCallback(async () => {
    const layerIds = layers.map((layer) => layer.type ?? layer.assetTag ?? layer.id)
    let cacheKey = JSON.stringify(layerIds)
    cacheKey += regionMasks ? JSON.stringify(regionMasks.map((mask) => mask.region)) : ''
    cacheKey += JSON.stringify(drawAreas)
    if (cachedAvailableIdentifyingInformation[cacheKey]) {
      return cachedAvailableIdentifyingInformation[cacheKey]
    }

    const availableIdentifyingInformation = potentialIdentifyingInformation.map(
      async (attribute) => {
        return {
          attribute,
          result: await fetchCheckIfIdentifyingInformationExists(
            attribute.property,
            layers.filter((layer) => layer.layerType === 'asset'),
          ),
        }
      },
    )
    const data = await Promise.all(availableIdentifyingInformation)
    const result = data.reduce((acc, { attribute, result }) => {
      result.forEach(({ asset_type, has_identifying_information }) => {
        if (has_identifying_information) {
          acc[asset_type] = [...(acc[asset_type] || []), attribute]
        }
      })
      return acc
    }, {} as { [key: string]: { name: string; property: string }[] })

    cachedAvailableIdentifyingInformation[cacheKey] = result
    setCachedAvailableIdentifyingInformation((prev) => {
      return { ...prev, [cacheKey]: result }
    })

    return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers, drawAreas, regionMasks])

  const memoizedIdentifyingInformationRiskData = useCallback(
    async (attribute: string) => {
      const hazardLayerIds = layers
        .filter((l) => l.layerType == 'hazard')
        .map((layer) => layer.type ?? layer.assetTag ?? layer.id)
      let cacheKey = attribute + '|' + hazardLayerIds.join(',')
      cacheKey += '|' + (regionMasks ? JSON.stringify(regionMasks.map((mask) => mask.region)) : '')
      cacheKey += '|' + JSON.stringify(drawAreas)

      if (hazardLayerIds.length < 1) return []

      let dataToReturn = cachedIdentifyingInformationRiskData[cacheKey] ?? []
      const layersNeedingData = layers.filter(
        (layer) =>
          layer.layerType === 'asset' &&
          selectedIdentifyingInformation[layer.type]?.property === attribute &&
          !dataToReturn.some((data) => data.asset_type === layer.type),
      )
      if (layersNeedingData.length === 0) return dataToReturn
      const hasElementFilters = Object.values(elementFilters).some(
        (filters) => Object.keys(filters).length > 0,
      )
      const additionalData = await fetchIdentifyingInformationAssetExposureStats(
        layersNeedingData,
        layers.filter((layer) => layer.layerType === 'hazard'),
        attribute,
        regionMasks ? regionMasks.map((mask) => mask.region) : [],
        drawAreas,
        hasElementFilters ? visibleAssets : undefined,
      )
      dataToReturn = [...dataToReturn, ...additionalData]
      setCachedIdentifyingInformationRiskData((prev) => {
        return { ...prev, [cacheKey]: dataToReturn }
      })

      return dataToReturn
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      layers,
      cachedIdentifyingInformationRiskData,
      regionMasks,
      drawAreas,
      visibleAssets,
      selectedIdentifyingInformation,
    ],
  )

  useEffect(() => {
    const callGetData = async () => {
      setIsLoading('exposure', true)
      try {
        const promises = [
          memoizedFilteredData().then((data) =>
            setReportRiskDetailData((d) => ({ ...d, default: data })),
          ),
          memoizedAvailableIdentifyingInformation().then((data) =>
            setAvailableIdentifyingInformation(data),
          ),
        ]
        await Promise.all(promises)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error fetching asset exposure stats:', e)
      } finally {
        setIsLoading('exposure', false)
      }
    }
    callGetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedFilteredData])

  useEffect(() => {
    const callGetData = async () => {
      setIsLoading('identifyingInfo', true)
      try {
        const activeIdentifyingInformation = Array.from(
          new Set(Object.values(selectedIdentifyingInformation)),
        )

        const promises = activeIdentifyingInformation.map((attribute) =>
          memoizedIdentifyingInformationRiskData(attribute.property).then((data) => {
            setReportRiskDetailData((d) => ({ ...d, [attribute.property]: data }))
          }),
        )
        await Promise.all(promises)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error fetching asset exposure stats:', e)
      } finally {
        setIsLoading('identifyingInfo', false)
      }
    }
    callGetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIdentifyingInformation, layers, drawAreas, regionMasks, visibleAssets])

  return (
    <RiskFetcherContext.Provider
      value={{
        riskToAssets: reportRiskDetailData,
        isLoading,
        availableIdentifyingInformation,
        setIdentifyingInformationForAsset,
        selectedIdentifyingInformation,
      }}
    >
      {children}
    </RiskFetcherContext.Provider>
  )
}

export const useRiskFetcher = () => useContext(RiskFetcherContext)

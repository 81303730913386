/** @jsxImportSource @emotion/react */

import LanguageIcon from '@mui/icons-material/Language'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Tooltip } from '@uintel/ui-component-library'
import { Button } from '@src/components/Atoms/Button'

import {
  content,
  footer,
  footerText,
  ShowOnMobile,
  MobileBodyText,
  MobileHeaderText,
  LandusePageContainer,
  LandusePageLeft,
  LandusePageLeftInnerContainer,
  LandusePageRight,
  unorderedListBox,
  logo,
  mainText,
  marketingButton,
  secondaryTextText,
  secondaryTextPrimary,
  socialLinks,
} from './LandusePage.styles'

export const LandusePage = () => {
  const theme = useTheme()

  return (
    <Box css={LandusePageContainer({ theme })}>
      <Box css={LandusePageRight({ theme })}>
        <Card sx={{ width: '40vw' }}>
          <CardMedia
            sx={{ height: '35vh' }}
            image="./images/pictures/landuse-marketing.png"
            title="Example of landuse functionality"
          />
          <CardContent>
            <Typography variant="h2" component="div" css={secondaryTextPrimary({ theme })}>
              Landuse
            </Typography>
            <Typography variant="body1">
              Explore land use scenarios and evaluate them against multiple criteria. How do
              preferences change where we should be developing? This tool supports strategic spatial
              planning.
              <br />
              <br />
              In real time:
            </Typography>
            <Box css={unorderedListBox({ theme })}>
              <ul>
                <li>Evaluate existing and future land use plans against multiple criteria</li>
                <li>Explore the effect of different land use criteria</li>
                <li>Explore the effect of risk aversion on future land use</li>
                <li>
                  Test criteria and land use options with communities, stakeholders, and elected
                  members
                </li>
              </ul>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box css={LandusePageLeft({ theme })}>
        <Box component="header">
          <a href="https://resilience-explorer.org/" target="_blank" rel="noreferrer">
            <Box component="img" src="logos/RexLogoFullWhite.png" css={logo} />
          </a>
        </Box>
        <Box component="main" css={content}>
          <Box css={ShowOnMobile}>
            <Box component="main" css={LandusePageLeftInnerContainer}>
              <Typography variant="h2" css={MobileHeaderText({ theme })}>
                Landuse
              </Typography>
              <Typography variant="body1" css={MobileBodyText({ theme })}>
                Coming Soon. We’re integrating our national accessibility map and providing more
              </Typography>
            </Box>
          </Box>
          <Typography variant="h2" css={mainText({ theme })}>
            Contact us to activate
          </Typography>
          <a
            href="https://resilience-explorer.org/contact-resilience-explorer/"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="outlined" color="secondary" css={marketingButton({ theme })}>
              Get in touch
            </Button>
          </a>
          <Typography variant="h6" css={secondaryTextText({ theme })}>
            Powered by
            <a href="https://urbanintelligence.co.nz" target="_blank" rel="noreferrer">
              Urban Intelligence
            </a>
          </Typography>
        </Box>

        <Box component="footer" css={footer({ theme })}>
          <Typography variant="h6" css={footerText({ theme })}>
            Find us at
          </Typography>
          <Box css={socialLinks({ theme })}>
            <Tooltip title="LinkedIn">
              <a
                href="https://www.linkedin.com/company/urban-intelligence-nz/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon />
              </a>
            </Tooltip>
            <Tooltip title="Twitter">
              <a href="https://twitter.com/Urban_Intel_NZ" target="_blank" rel="noreferrer">
                <TwitterIcon />
              </a>
            </Tooltip>
            <Tooltip title="Website">
              <a href="https://resilience-explorer.org/" target="_blank" rel="noreferrer">
                <LanguageIcon />
              </a>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

/** @jsxImportSource @emotion/react */

import { cascadingLayerPresent, MapLayer } from '@redux/map/mapSlice'
import { Icon } from '@src/components/Atoms/Icon'
import { useTheme } from '@mui/material'
import { CascadingNDaysSelector } from '@src/components/Molecules/SideDrawerContent/components/CascadingNDaysSelector/CascadingNDaysSelector'

import { useSelector } from 'react-redux'
import { Accordion } from '@src/components/Atoms/Accordion'
import { variantColor } from '@src/components/Atoms/Accordion/Accordion.styles'
import { GroupedLayers } from '../InformativePanel'
import { RiskData } from '@contexts/RiskFetcherContext'
import { RiskAccordion } from './RiskAccordion'

interface ElementAccordionProps {
  layer: MapLayer
  groupedLayers: GroupedLayers
  animationDelay: number
  riskToAssets: { [key: string]: RiskData[] }
  isLoading: { [key: string]: boolean }
}

export const ElementAccordion = ({
  layer,
  groupedLayers,
  animationDelay,
  riskToAssets,
  isLoading,
}: ElementAccordionProps) => {
  const theme = useTheme()

  const hasCascadingLayers = useSelector(cascadingLayerPresent)

  if (!layer) return null
  const title = layer.display_name

  const iconColour = variantColor({ theme, variant: 'clear' })
  return (
    <Accordion
      defaultExpanded={true}
      key={`sidedrawer-accordion-${layer.assetTag ?? layer.id ?? layer.type}`}
      title={title}
      animationDelay={animationDelay}
      variant={'clear'}
      level={'h1'}
      hasTextContent={false}
      body={
        <>
          {hasCascadingLayers && layer.is_cascading && (
            <CascadingNDaysSelector
              key={layer.type ?? layer.assetTag}
              layer={layer}
              inAccordion={true}
            />
          )}
          {groupedLayers.hazards.map((hazardLayer) => (
            <RiskAccordion
              key={hazardLayer.hazard_type}
              hazardGroup={hazardLayer}
              elementLayer={layer}
              defaultExpanded={true}
              riskToAssets={riskToAssets}
              isLoading={isLoading}
            />
          ))}
        </>
      }
      icon={<Icon colour={iconColour} iconName={layer.icon} />}
    />
  )
}

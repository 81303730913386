/** @jsxImportSource @emotion/react */

import { MapLayer, MapLayerType } from '@redux/map/mapSlice'
import { Section, Tabs } from '@uintel/ui-component-library'
import { DialogData } from '../AddLayersDialog.types'
import { AssetLayersForm } from '../AssetLayersForm'
import { HazardLayersForm } from '../HazardLayersForm'
import { InformationLayersForm } from '../InformationLayersForm'
import { addLayersSetupSection, addLayersTabsContainer } from './LayerSetup.styles'
import { useMap } from '@contexts/MapContext'
import { usePreferences } from '@contexts/PreferencesContext'

export interface LayerSetupProps {
  dialogData: DialogData
  selectedHazards: MapLayer[]
  selectedAssets: MapLayer[]
  selectedInformation: MapLayer[]
  onSelectHazardLayer: (selectedHazard: MapLayer) => void
  onSelectInformationLayer: (selectedInformation: MapLayer) => void
  onSelectAssets: (selectedAsset: MapLayer[]) => void
  onRemoveLayer: (layer: MapLayer, type: MapLayerType) => void
}

export const LayerSetup = ({
  dialogData,
  selectedHazards,
  selectedAssets,
  selectedInformation,
  onSelectHazardLayer,
  onSelectInformationLayer,
  onSelectAssets,
  onRemoveLayer,
}: LayerSetupProps) => {
  const { term_preference } = usePreferences()
  const { showAddLayersModalTab, setShowAddLayersModalTab } = useMap()

  const { assetDialogData, hazardDialogData } = dialogData
  let { informationDialogData } = dialogData

  // allows backward compatibility with old informationDialogData
  if (Array.isArray(informationDialogData)) {
    informationDialogData = {
      contextual_layers: {
        title: 'Contextual Layers',
        icon: 'Tree',
        layers: [...informationDialogData],
      },
    }
  }

  const handleTabChange = (index: number) => {
    if (index === 0) {
      setShowAddLayersModalTab('element')
    } else if (index === 1) {
      setShowAddLayersModalTab('hazard')
    } else {
      setShowAddLayersModalTab('contextual')
    }
  }

  return (
    <Section css={addLayersSetupSection} className="tutorial-addlayers-panel">
      <Tabs
        css={addLayersTabsContainer}
        tabIndex={
          showAddLayersModalTab === 'hazard' ? 1 : showAddLayersModalTab === 'contextual' ? 2 : 0
        }
        handleChangeTab={handleTabChange}
        tabs={[
          {
            label: 'ELEMENTS',
            content: assetDialogData ? (
              <AssetLayersForm
                assetDialogData={assetDialogData}
                selectedAssets={selectedAssets}
                selectedHazards={selectedHazards}
                onSelectAssets={onSelectAssets}
                onRemoveLayer={onRemoveLayer}
              />
            ) : null,
          },
          {
            label: term_preference.hazard ? 'HAZARDS' : 'RISK SOURCES',
            content: dialogData.hazardDialogData ? (
              <HazardLayersForm
                selectedHazards={selectedHazards}
                selectedAssets={selectedAssets}
                hazardDialogData={hazardDialogData}
                onSelectHazardLayer={onSelectHazardLayer}
                onRemoveLayer={onRemoveLayer}
              />
            ) : null,
          },
          {
            label: 'CONTEXTUAL',
            content: informationDialogData ? (
              <InformationLayersForm
                selectedInformation={selectedInformation}
                informationDialogData={informationDialogData}
                onSelectInformationLayer={onSelectInformationLayer}
                onRemoveLayer={onRemoveLayer}
              />
            ) : null,
          },
        ]}
      />
      {/* <Typography variant="body1" css={addLayersSectionTitle({ theme })}>
        Layer Setup
      </Typography> */}
    </Section>
  )
}

import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const addLayersDialogContainer = ({ theme }: { theme: Theme }) => css`
  background-color: ${theme.palette.white.main};
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  border-radius: ${theme.spacing(1)};
  padding: ${theme.spacing(3)};

  gap: ${theme.spacing(2)};

  box-shadow: ${theme.shadows[10]};

  width: 1100px;

  max-width: 95vw;
  max-height: 95svh;

  @media (max-width: 1080px) {
    overflow: auto;
  }
`

export const addLayersDialogTitle = css`
  width: 100%;
  font-weight: 700;
  text-align: center;
`

export const addLayersSectionTitle = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  text-align: center;
  font-weight: 700;
  margin-bottom: ${theme.spacing(2)};
`

export const addLayersFooter = ({ theme }: { theme: Theme }) => css`
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing(2)};
`

export const loadingSection = ({ theme }: { theme: Theme }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border: 1px solid ${theme.palette.grey[800]}40;
  border-radius: ${theme.spacing(1)};
`

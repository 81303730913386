/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material/styles'
import {
  hazardControlShownScenario,
  scenarioHeader,
  scenarioParameterBox,
  scenarioParameterBoxContent,
  scenarioParameterBoxHeader,
} from './HazardControlShownScenario.styles'
import { HazardDetail } from '@uintel/ui-component-library/dist/components/Molecules/LayersControl/AddLayersDialog'
import { HazardScenario } from '@src/components/Molecules/MapView/AddLayersDialog'
import { Dispatch, Fragment, SetStateAction } from 'react'
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import { Icon } from '@src/components/Atoms/Icon'
import { CombinedHazardDetail } from '../HazardControls'
import { HazardOptionState } from '@src/components/Molecules/MapView/AddLayersDialog/HazardLayersForm/HazardBox'
import { convertToMetersOrCentimeters } from '@uintel/ui-component-library'
import { usePreferences } from '@contexts/PreferencesContext'

export interface HazardControlShownScenarioProps {
  hazardDetail: HazardDetail | CombinedHazardDetail
  scenario: HazardScenario | undefined
  setInformationDetailsModal: Dispatch<
    SetStateAction<{
      isOpen: boolean
      helpFileName: string
    }>
  >
  hazardOptionState: HazardOptionState
}

const displayValueText = (value: string | number, unit?: string, suffix?: string) => {
  let big_value = ''
  let small_value = ''
  if (value.toString().length < 12) {
    // check if value is a number or can be a number
    if (isNaN(Number(value))) {
      // value is a string
      // use regex to check if value ends with text in brackets
      const regex = /\(([^)]+)\)/
      const match = regex.exec(value.toString())
      if (match) {
        small_value = match[0].substring(1, match[0].length - 1)
        big_value = value.toString().replace(match[0], '')
      } else {
        big_value = value.toString()
      }
    } else {
      big_value = convertToMetersOrCentimeters(Number(value), unit)
    }
  } else {
    small_value = value.toString()
  }
  return (
    <>
      {big_value && (
        <Typography className="big-value">
          {big_value.trim()}
          {suffix}
        </Typography>
      )}
      {small_value && (
        <Typography className="small-value">
          {small_value.trim()}
          {suffix}
        </Typography>
      )}
    </>
  )
}

interface ParameterInfo {
  key: string
  title: string
  value: string | number
  unit?: string
}

export const HazardControlShownScenario = ({
  hazardDetail,
  scenario,
  setInformationDetailsModal,
  hazardOptionState,
}: HazardControlShownScenarioProps) => {
  const theme = useTheme()
  const { term_preference } = usePreferences()

  const sortedForm = hazardDetail.form.toSorted((a, b) => a.priority - b.priority)
  const parameters = sortedForm.map<ParameterInfo>((formInput) => {
    const value =
      hazardDetail.parameters[formInput.key]?.keyValues?.[
        scenario?.parameters[formInput.key] as string
      ] ??
      scenario?.parameters[formInput.key] ??
      ''
    return {
      key: formInput.key,
      title: formInput.title,
      value: value,
      unit: hazardDetail.parameters[formInput.key]?.unit,
    }
  })

  return (
    <Box css={hazardControlShownScenario({ theme })}>
      <Typography css={scenarioHeader}>
        ACTIVE {term_preference.hazard ? 'HAZARD' : 'RISK'} SCENARIO
      </Typography>
      {scenario
        ? parameters.map((parameter, i) => {
            const availableValues = hazardDetail.scenarios
              .map((scenario) => scenario.parameters[parameter.key])
              .filter((value, index, self) => self.indexOf(value) === index)

            if (availableValues.length <= 1) return null

            return (
              <Fragment key={parameter.key}>
                {i > 0 && <Divider css={{ borderColor: theme.palette.grey[100] }} />}
                <Box css={scenarioParameterBox}>
                  <Box css={scenarioParameterBoxHeader}>
                    <Typography variant="body1">{parameter.title}</Typography>
                    <Tooltip title={parameter.title}>
                      <IconButton
                        size="small"
                        onClick={() =>
                          setInformationDetailsModal({
                            isOpen: true,
                            helpFileName: parameter.key,
                          })
                        }
                      >
                        <Icon iconName="Info" colour={theme.palette.black[500]} size="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box css={scenarioParameterBoxContent({ theme })}>
                    {displayValueText(
                      parameter.value,
                      parameter.unit,
                      hazardOptionState[parameter.key]?.value === '' ? '*' : '',
                    )}
                  </Box>
                </Box>
              </Fragment>
            )
          })
        : null}
      <Box className="no-filter">* Automatically chosen based on selected controls</Box>
    </Box>
  )
}

import * as d3 from 'd3'
import { useEffect, useRef } from 'react'
import { round, roundForDisplay } from './utils/utils'

export const MakeGaugeSvg = (
  element: HTMLElement,
  value: number,
  total: number,
  unit?: string,
  prefix = '',
) => {
  // set the dimensions and margins of the graph
  const fullWidth = 200
  const fullHeight = 130
  const margin = { top: 10, right: 10, bottom: 10, left: 10 }
  const width = fullWidth - margin.left - margin.right

  let displayPercentage = ''
  let gaugePos = 0
  if (value == 0) displayPercentage = '0%'
  else {
    const proportion = value / total
    gaugePos = proportion * 192
    const percentage = Math.round(proportion * 100)
    if (proportion < 0.01) displayPercentage = '<1%'
    else displayPercentage = `${percentage}%`
  }

  d3.select(element).selectAll('svg').remove()

  const svg = d3
    .select(element)
    .append('svg')
    .attr('version', '1.1')
    .attr('xmlns', 'http://www.w3.org/2000/svg')
    .attr('width', fullWidth)
    .attr('height', fullHeight)
    .attr('viewBox', `0 0 ${fullWidth} ${fullHeight}`)
    .attr('color', 'black')
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`)

  const radius = Math.floor(width / 2.4)
  const thickness = radius * 0.25
  const arc = d3.arc().cornerRadius(thickness / 2)
  const body = svg.append('g').attr('transform', `translate(${width / 2},${radius})`)

  const gaugeBody = body.append('g').attr('transform', `rotate(-90)`)
  gaugeBody
    .append('path')
    .attr(
      'd',
      arc({
        startAngle: deg2rad(-8),
        endAngle: deg2rad(188),
        outerRadius: radius,
        innerRadius: radius - thickness,
      }),
    )
    .attr('fill', '#e0e4e8')

  gaugeBody
    .append('path')
    .attr(
      'd',
      arc({
        startAngle: deg2rad(-6),
        endAngle: deg2rad(gaugePos),
        outerRadius: radius - 2,
        innerRadius: radius - thickness + 2,
      }),
    )
    .attr('fill', '#c43424')

  body
    .append('text')
    .text(displayPercentage)
    .attr('x', 0)
    .attr('y', 0)
    .attr('style', 'text-anchor: middle; font-size: 24pt; font-weight: 800; fill: #0b2948;')

  const formattedValue = prefix === '$' ? roundForDisplay(value, 2) : round(value, 2)
  const formattedTotal = prefix === '$' ? roundForDisplay(total, 2) : round(total, 2)
  const caption1 = `${prefix}${formattedValue.toLocaleString()} out of`
  const caption2 = `${prefix}${formattedTotal.toLocaleString()} ${unit}`
  body
    .append('text')
    .attr('x', 0)
    .attr('y', 24)
    .attr('style', 'text-anchor: middle; font-size: 8pt; fill: #8392a1;')
    .text(caption1)
  body
    .append('text')
    .attr('x', 0)
    .attr('y', 36)
    .attr('style', 'text-anchor: middle; font-size: 8pt; fill: #8392a1;')
    .text(caption2)
}

interface GaugeProps {
  value: number
  total: number
  unit?: string
  prefix?: string
}
export const Gauge = ({ value, total, unit, prefix }: GaugeProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current) return
    MakeGaugeSvg(ref.current, value, total, unit, prefix)
  }, [value, total, unit, prefix])

  return <div ref={ref}></div>
}

function deg2rad(deg: number): number {
  return (Math.PI / 180.0) * deg
}

/** @jsxImportSource @emotion/react */

import { MapLayer } from '@redux/map/mapSlice'
import { Icon } from '@src/components/Atoms/Icon'
import { RiskContent } from './RiskContent'
import { useTheme } from '@mui/material'

import { Accordion } from '@src/components/Atoms/Accordion'
import { variantColor } from '@src/components/Atoms/Accordion/Accordion.styles'
import { HazardGroup } from '../InformativePanel'
import { RiskData } from '@contexts/RiskFetcherContext'
import { usePreferences } from '@contexts/PreferencesContext'

interface RiskAccordionProps {
  hazardGroup: HazardGroup
  elementLayer: MapLayer
  defaultExpanded: boolean
  riskToAssets: { [key: string]: RiskData[] }
  isLoading: { [key: string]: boolean }
}

export const RiskAccordion = ({
  hazardGroup,
  elementLayer,
  riskToAssets,
  isLoading,
}: RiskAccordionProps) => {
  const theme = useTheme()
  const { use_hazard_name } = usePreferences()

  let title = ''
  const separateScenarios = hazardGroup.scenarios.length > 1

  title = hazardGroup.hazard_type
  const layer = hazardGroup.scenarios[0].layer
  if (!separateScenarios) {
    title =
      layer.hazard_name && layer.scenario_name
        ? `${
            use_hazard_name.flag
              ? layer.hazard_name + ' ' + layer.scenario_name
              : layer.display_name
          }`
        : layer.display_name
  }

  // Split hazard into scenarios
  const hazardBody = (scenario: HazardGroup['scenarios'][0]) => {
    if (!scenario.layer) return null
    return (
      <RiskContent
        assetLayer={elementLayer}
        hazardLayer={scenario.layer}
        riskToAssets={riskToAssets}
        isLoading={isLoading}
      />
    )
  }

  if (!layer) return null

  const iconColour = variantColor({ theme, variant: 'filled' })
  return (
    <Accordion
      defaultExpanded={true}
      key={`sidedrawer-accordion-${layer.assetTag ?? layer.id ?? layer.type}`}
      title={title}
      variant={'filled'}
      level={'h3'}
      hasTextContent={true}
      body={
        <>
          {hazardGroup &&
            (separateScenarios
              ? hazardGroup.scenarios.map((scenario) => (
                  <Accordion
                    key={scenario.scenario}
                    priority={2}
                    defaultExpanded={true}
                    title={scenario.scenario}
                    body={<>{hazardBody(scenario)}</>}
                    variant={'outline'}
                    level="h3"
                  />
                ))
              : hazardBody(hazardGroup.scenarios[0]))}
        </>
      }
      icon={<Icon colour={iconColour} iconName={layer.icon} />}
    />
  )
}

import { css } from '@emotion/react'
import { Theme } from '@mui/material'
import { theme } from '@uintel/ui-component-library'

export const layerDetailsHeaderWrapper = css`
  width: 100%;
`

export const layerDetailsHeaderContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: space-between;

  padding-left: ${theme.spacing(2)};
`

export const accordionsContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`

export const layerDetailsHeaderTitle = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${theme.spacing(2)};
  color: ${theme.palette.primary.dark};
`

export const layerDetailsHeaderIcon = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.primary.dark};
`

export const layerDetailsContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(8)};
`

export const layerDetailsAccordionHeader = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(0.5)};
`

export const layerDetailsHeader = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: ${theme.palette.primary.dark};
  padding: ${theme.spacing(1)} 0;
`

export const layerDetailsAccordionContent = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;

  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(0.5)};
  font-size: 14px;

  table {
    width: 100%;
    border-spacing: 0;

    caption {
      padding: 8px 0;
      border: 1px solid ${theme.palette.grey[300]};
      font-weight: 700;
      background-color: ${theme.palette.background.paper};
      border-radius: 4px 4px 0 0;
    }

    th {
      padding: ${theme.spacing(1)};
      font-weight: 700;
      border-bottom: 1px solid ${theme.palette.grey[300]};
      border-right: 1px solid ${theme.palette.grey[300]};
      background-color: ${theme.palette.background.paper};

      :first-of-type {
        border-left: 1px solid ${theme.palette.grey[300]};
      }
    }

    td {
      padding: ${theme.spacing(1)};
      text-align: right;
      border-bottom: 1px solid ${theme.palette.grey[300]};
      border-right: 1px solid ${theme.palette.grey[300]};

      :first-of-type {
        border-left: 1px solid ${theme.palette.grey[300]};
      }
    }

    tr:nth-of-type(even) {
      background-color: ${theme.palette.background.paper};
    }
  }
`

export const layerDetailsCoveredArea = ({ theme }: { theme: Theme }) => css`
  display: flex;
  gap: ${theme.spacing(1)};

  padding-top: ${theme.spacing(2)};
`

export const layerDetailsCoveredAreaContent = ({ theme }: { theme: Theme }) => css`
  display: flex;
  gap: ${theme.spacing(1)};
  flex-wrap: wrap;
  margin: ${theme.spacing(2)} 0;
`

export const layerDetailsCoveredAreaRegions = ({ theme }: { theme: Theme }) => css`
  background-color: ${theme.palette.white.light};
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  border-radius: ${theme.shape.borderRadius}px;
  border: 1px solid ${theme.palette.grey[300]};
`

export const layerDetailsCoveredAreaDivider = ({ theme }: { theme: Theme }) => css`
  margin: 0 ${theme.spacing(2)};
`

export const layerDetailDetails = ({ theme }: { theme: Theme }) => css`
  min-width: 200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${theme.spacing(2)};

  a {
    color: ${theme.palette.text.primary};
  }
`

export const layerDetailSources = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${theme.spacing(1)};
`

export const layerDetailButton = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  margin-top: ${theme.spacing(2)};
  flex: 1 1 auto;
  border: 1px solid ${theme.palette.primary.dark};

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  & > a,
  & > svg {
    text-decoration: none;
    color: ${theme.palette.primary.dark};
  }

  &:hover {
    background-color: ${theme.palette.primary.dark}10;
    border-color: ${theme.palette.primary.dark};
  }
`

export const layerDetailButtonIcon = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.primary.main};
  width: 18px;
  height: 18px;
  padding-top: 2px;
`

export const layerDetailConfidence = ({ theme, colour }: { theme: Theme; colour: string }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
  padding: ${theme.spacing(1)};
  padding-left: ${theme.spacing(3)};
  border: 1px solid ${theme.palette.grey[300]};
  border-radius: ${theme.shape.borderRadius}px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${theme.spacing(1)};
    height: 100%;
    background-color: ${colour ?? theme.palette.grey[500]};
    border-radius: ${theme.shape.borderRadius}px;
  }
`

export const layerDetailImagesContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-wrap: wrap;

  align-items: flex-end;
  grid-gap: ${theme.spacing(2)};
  margin-top: ${theme.spacing(2)};
`

export const layerDetailImage = ({ theme }: { theme: Theme }) => css`
  flex: 1 1 auto;
  min-width: 220px;
  overflow: hidden;
  width: 220px;
  display: flex;
  align-items: stretch;

  border-radius: ${theme.shape.borderRadius}px;
  img {
    object-fit: contain;
    width: 100%;
  }

  border: 1px solid ${theme.palette.grey[300]};
`

export const layerNotFoundContainer = css`
  position: absolute;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  overflow: hidden;
  margin: ${theme.spacing(12)} 0;
`

export const layerNotFoundImage = ({ url }: { url: string }) => css`
  width: 100%;
  min-height: 280px;

  background-image: url(${url});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

/** @jsxImportSource @emotion/react */
import { Box, Collapse, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { SwatchItem, Tooltip } from '@uintel/ui-component-library'
import { Icon } from '@src/components/Atoms/Icon'
import {
  legendContainer,
  legendContent,
  legendHeader,
  minimizeHeader,
  normalHeader,
} from './Legend.styles'
import { LegendCategorical } from './LegendCategorical'
import { LegendContinuous, LegendContinuousStop } from './LegendContinuous'
import { LegendKeyValue, LegendKeyValues } from './LegendContinuous/LegendKeyValue'
import { memo, useMemo, useState } from 'react'
import { Add, Remove } from '@mui/icons-material'

export interface LegendItem extends SwatchItem {
  label: string
}

export interface LegendData {
  title: string
  sections: LegendSection[]
  helpDialog?: string
}

export interface LegendSection {
  type: 'categorical' | 'continuous' | 'keyValue'
  dataColumn?: string
  subtitle?: string
  stops?: LegendContinuousStop[]
  items?: LegendItem[]
  keyValues?: LegendKeyValues
  keys?: string[]
}

export interface LegendProps {
  title: string
  sections: LegendSection[]
  helpDialog?: string
  toggleMinimized?: () => void
  minimized?: boolean
}

export type LegendLookup = {
  [key: string]: LegendProps
}

export const Legend = memo(
  function LegendComp({ title, sections, helpDialog, minimized, toggleMinimized }: LegendProps) {
    const theme = useTheme()

    if (!title) title = 'Legend'

    const [_minimized, setMinimized] = useState(false)

    const isMinimized = useMemo(() => minimized ?? _minimized, [_minimized, minimized])

    const handleToggleMinimized = () => {
      setMinimized((a) => !a)
      if (toggleMinimized) toggleMinimized()
    }

    return (
      <Box css={legendContainer({ theme })} onClick={handleToggleMinimized}>
        <Box css={legendHeader({ theme })}>
          <Box className="normal-header" css={normalHeader({ theme })}>
            <Typography>{title ?? ''}</Typography>
            {helpDialog != undefined && (
              <Tooltip title="More info">
                <Box onClick={(e) => e.stopPropagation()}>
                  <Icon iconName="Info" size="small" colour={theme.palette.grey[500]} />
                </Box>
              </Tooltip>
            )}
            {isMinimized ? <Add /> : <Remove />}
          </Box>
          <Box className="minimize-header" css={minimizeHeader({ theme })}>
            <Typography>Click to {isMinimized ? 'expand' : 'hide'}</Typography>
            {isMinimized ? <Add /> : <Remove />}
          </Box>
        </Box>
        <Collapse in={!isMinimized}>
          <Box css={legendContent({ theme })}>
            {sections
              ? sections.map((section, sectionIndex) => (
                  <Box key={sectionIndex + (section.subtitle ?? '')}>
                    {section.subtitle && <Typography>{section.subtitle}</Typography>}
                    {section.type === 'categorical' && (
                      <LegendCategorical items={section.items ?? []} />
                    )}
                    {section.type === 'continuous' && <LegendContinuous />}
                    {section.type === 'keyValue' && (
                      <LegendKeyValue
                        keys={section.keys ?? []}
                        keyValues={section.keyValues ?? {}}
                      />
                    )}
                  </Box>
                ))
              : null}
          </Box>
        </Collapse>
      </Box>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.minimized === nextProps.minimized
  },
)
